// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import Select from "react-select";
// import * as XLSX from "xlsx"; // Import xlsx
// import config from "./config";
// import "./Survey.css";
// import "./Data.css";

// const Data = () => {
//   const [surveyData, setSurveyData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [error, setError] = useState(null);

//   // Filter state
//   const [filters, setFilters] = useState({
//     MU: null,
//     CU: null,
//     assessed_by: null,
//     email: null,
//     mode: null,
//   });

//   useEffect(() => {
//     // Fetch survey data from the backend
//     axios
//       .get(`${config.API_BASE_URL}/fetch-results`)
//       .then((response) => {
//         setSurveyData(response.data);
//         setFilteredData(response.data); // Set initial filtered data
//       })
//       .catch((error) => {
//         setError("Error fetching survey data");
//       });
//   }, []);

//   // Extract unique values for each filter
//   const getUniqueOptions = (key) => {
//     const uniqueValues = [...new Set(surveyData.map((item) => item[key]))];
//     return uniqueValues.map((value) => ({ value, label: value }));
//   };

//   // Handle filter change
//   const handleFilterChange = (selectedOption, name) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       [name]: selectedOption,
//     }));
//   };

//   // Filter data when filter values change
//   useEffect(() => {
//     let data = surveyData;

//     // Apply filters
//     if (filters.MU) {
//       data = data.filter((item) => item.MU === filters.MU.value);
//     }
//     if (filters.CU) {
//       data = data.filter((item) => item.CU === filters.CU.value);
//     }
//     if (filters.assessed_by) {
//       data = data.filter(
//         (item) => item.assessed_by === filters.assessed_by.value
//       );
//     }
//     if (filters.email) {
//       data = data.filter((item) => item.email === filters.email.value);
//     }
//     if (filters.mode) {
//       data = data.filter((item) => item.mode === filters.mode.value);
//     }

//     setFilteredData(data);
//   }, [filters, surveyData]);

//   // Function to download data as Excel
//   const downloadExcel = () => {
//     const worksheet = XLSX.utils.json_to_sheet(filteredData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "SurveyData");
//     XLSX.writeFile(workbook, "survey_data.xlsx"); // Name of the file
//   };

//   return (
//     <div className="tablediv">
//       <h1>Survey Data</h1>
//       {error && <p>{error}</p>}

//       <div className="top-navbar">
//   <h4>Filters</h4>
//   <div className="filter-group">
//     <div>
//       <label>CU:</label>
//       <input
//         list="cuOptions"
//         value={cu}
//         onChange={(e) => setCU(e.target.value)}
//         placeholder="Type CU here..."
//       />
//       <datalist id="cuOptions">
//         {getUniqueOptions("CU").map((option, index) => (
//           <option key={index} value={option} />
//         ))}
//       </datalist>
//     </div>
//     <div>
//       <label>MU:</label>
//       <input
//         list="muOptions"
//         value={mu}
//         onChange={(e) => setMU(e.target.value)}
//         placeholder="Type MU here..."
//       />
//       <datalist id="muOptions">
//         {getUniqueOptions("MU").map((option, index) => (
//           <option key={index} value={option} />
//         ))}
//       </datalist>
//     </div>
//     <div>
//       <label>Assessed By:</label>
//       <input
//         list="assessedByOptions"
//         value={assessedBy}
//         onChange={(e) => setAssessedBy(e.target.value)}
//         placeholder="Type Assessed By here..."
//       />
//       <datalist id="assessedByOptions">
//         {getUniqueOptions("assessed_by").map((option, index) => (
//           <option key={index} value={option} />
//         ))}
//       </datalist>
//     </div>
//     <div>
//       <label>Email:</label>
//       <input
//         list="emailOptions"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//         placeholder="Type Email here..."
//       />
//       <datalist id="emailOptions">
//         {getUniqueOptions("email").map((option, index) => (
//           <option key={index} value={option} />
//         ))}
//       </datalist>
//     </div>
//     <div>
//       <label>Mode:</label>
//       <input
//         list="modeOptions"
//         value={mode}
//         onChange={(e) => setMode(e.target.value)}
//         placeholder="Type Mode here..."
//       />
//       <datalist id="modeOptions">
//         {getUniqueOptions("mode").map((option, index) => (
//           <option key={index} value={option} />
//         ))}
//       </datalist>
//     </div>
//     <Button label="Apply" onClick={handleApplyFilters}></Button>
//   </div>
// </div>

//       {/* Filter Inputs */}
//       {/* <div className="filter-section">
//         <label>
//           MU:
//           <Select
//             options={getUniqueOptions("MU")}
//             onChange={(selectedOption) =>
//               handleFilterChange(selectedOption, "MU")
//             }
//             isClearable
//           />
//         </label>
//         <label>
//           CU:
//           <Select
//             options={getUniqueOptions("CU")}
//             onChange={(selectedOption) =>
//               handleFilterChange(selectedOption, "CU")
//             }
//             isClearable
//           />
//         </label>
//         <label>
//           Assessed by:
//           <Select
//             options={getUniqueOptions("assessed_by")}
//             onChange={(selectedOption) =>
//               handleFilterChange(selectedOption, "assessed_by")
//             }
//             isClearable
//           />
//         </label>
//         <label>
//           Email:
//           <Select
//             options={getUniqueOptions("email")}
//             onChange={(selectedOption) =>
//               handleFilterChange(selectedOption, "email")
//             }
//             isClearable
//           />
//         </label>
//         <label>
//           Mode:
//           <Select
//             options={getUniqueOptions("mode")}
//             onChange={(selectedOption) =>
//               handleFilterChange(selectedOption, "mode")
//             }
//             isClearable
//           />
//         </label>
//       </div> */}

//       {/* Button to Download Excel */}
//       <button onClick={downloadExcel} className="download-button">
//         Download Excel
//       </button>
//       <table border="1">
//         <thead>
//           <tr>
//             <th>id</th>
//             <th>MU</th>
//             <th>CU</th>
//             <th>Assessed by</th>
//             <th>Email</th>
//             <th>Start Time</th>
//             <th>End Time</th>
//             <th>Mode</th>
//             <th>Q1_OP</th>
//             <th>Q1_FS</th>
//             <th>Q2_OP</th>
//             <th>Q2_FS</th>
//             <th>Q3_OP</th>
//             <th>Q3_FS</th>
//             <th>Q4_OP</th>
//             <th>Q4_FS</th>
//             <th>Q5_OP</th>
//             <th>Q5_FS</th>
//             <th>Q6_OP</th>
//             <th>Q6_FS</th>
//             <th>Q7_OP</th>
//             <th>Q7_FS</th>
//             <th>Q8_OP</th>
//             <th>Q8_FS</th>
//             <th>Q9_OP</th>
//             <th>Q9_FS</th>
//             <th>Q10_OP</th>
//             <th>Q10_FS</th>
//             <th>Q11_OP</th>
//             <th>Q11_FS</th>
//             <th>Q12_OP</th>
//             <th>Q12_FS</th>
//             <th>Q13_OP</th>
//             <th>Q13_FS</th>
//             <th>Q14_OP</th>
//             <th>Q14_FS</th>
//             <th>Q15_OP</th>
//             <th>Q15_FS</th>
//             <th>Q16_OP</th>
//             <th>Q16_FS</th>
//             <th>Q17_OP</th>
//             <th>Q17_FS</th>
//             <th>Q18_OP</th>
//             <th>Q18_FS</th>
//             <th>Q19_OP</th>
//             <th>Q19_FS</th>
//             <th>Q20_OP</th>
//             <th>Q20_FS</th>
//             <th>Q21_OP</th>
//             <th>Q21_FS</th>
//             <th>Q22_OP</th>
//             <th>Q22_FS</th>
//             <th>Section 1 Selected Score FS</th>
//             <th>Section 1 Selected Score OP</th>
//             <th>Section 2 Selected Score FS</th>
//             <th>Section 2 Selected Score OP</th>
//             <th>Section 3 Selected Score FS</th>
//             <th>Section 3 Selected Score OP</th>
//             <th>Section 4 Selected Score FS</th>
//             <th>Section 4 Selected Score OP</th>
//             <th>Total Score FS</th>
//             <th>Total Score OP</th>
//             <th>Selected Model</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredData.map((result, index) => (
//             <tr key={index}>
//               <td>{result.id}</td>
//               <td>{result.MU}</td>
//               <td>{result.CU}</td>
//               <td>{result.assessed_by}</td>
//               <td>{result.email}</td>
//               <td>{result.start_time}</td>
//               <td>{result.end_time}</td>
//               <td>{result.mode}</td>
//               <td>{result.Q1_OP}</td>
//               <td>{result.Q1_FS}</td>
//               <td>{result.Q2_OP}</td>
//               <td>{result.Q2_FS}</td>
//               <td>{result.Q3_OP}</td>
//               <td>{result.Q3_FS}</td>
//               <td>{result.Q4_OP}</td>
//               <td>{result.Q4_FS}</td>
//               <td>{result.Q5_OP}</td>
//               <td>{result.Q5_FS}</td>
//               <td>{result.Q6_OP}</td>
//               <td>{result.Q6_FS}</td>
//               <td>{result.Q7_OP}</td>
//               <td>{result.Q7_FS}</td>
//               <td>{result.Q8_OP}</td>
//               <td>{result.Q8_FS}</td>
//               <td>{result.Q9_OP}</td>
//               <td>{result.Q9_FS}</td>
//               <td>{result.Q10_OP}</td>
//               <td>{result.Q10_FS}</td>
//               <td>{result.Q11_OP}</td>
//               <td>{result.Q11_FS}</td>
//               <td>{result.Q12_OP}</td>
//               <td>{result.Q12_FS}</td>
//               <td>{result.Q13_OP}</td>
//               <td>{result.Q13_FS}</td>
//               <td>{result.Q14_OP}</td>
//               <td>{result.Q14_FS}</td>
//               <td>{result.Q15_OP}</td>
//               <td>{result.Q15_FS}</td>
//               <td>{result.Q16_OP}</td>
//               <td>{result.Q16_FS}</td>
//               <td>{result.Q17_OP}</td>
//               <td>{result.Q17_FS}</td>
//               <td>{result.Q18_OP}</td>
//               <td>{result.Q18_FS}</td>
//               <td>{result.Q19_OP}</td>
//               <td>{result.Q19_FS}</td>
//               <td>{result.Q20_OP}</td>
//               <td>{result.Q20_FS}</td>
//               <td>{result.Q21_OP}</td>
//               <td>{result.Q21_FS}</td>
//               <td>{result.Q22_OP}</td>
//               <td>{result.Q22_FS}</td>
//               <td>{result.section1_selectedScoreFS}</td>
//               <td>{result.section1_selectedScoreOP}</td>
//               <td>{result.section2_selectedScoreFS}</td>
//               <td>{result.section2_selectedScoreOP}</td>
//               <td>{result.section3_selectedScoreFS}</td>
//               <td>{result.section3_selectedScoreOP}</td>
//               <td>{result.section4_selectedScoreFS}</td>
//               <td>{result.section4_selectedScoreOP}</td>
//               <td>{result.totalScoreFS}</td>
//               <td>{result.totalScoreOP}</td>
//               <td>{result.selected_model}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Data;

import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx"; // Import xlsx
import config from "./config";
import "./Survey.css";
import "./Data.css";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

const Data = () => {
  const [surveyData, setSurveyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Filter state
  const [filters, setFilters] = useState({
    MU: null,
    CU: null,
    assessed_by: null,
    email: null,
    mode: null,
  });

  // Individual state for inputs
  const [cu, setCU] = useState("");
  const [mu, setMU] = useState("");
  const [assessedBy, setAssessedBy] = useState("");
  const [email, setEmail] = useState("");
  const [mode, setMode] = useState("");

  useEffect(() => {
    // Fetch survey data from the backend
    axios
      .get(`${config.API_BASE_URL}/fetch-results`)
      .then((response) => {
        setSurveyData(response.data);
        setFilteredData(response.data); // Set initial filtered data
      })
      .catch((error) => {
        setError("Error fetching survey data");
      });
  }, []);

  // Extract unique values for each filter
  const getUniqueOptions = (key) => {
    const uniqueValues = [...new Set(surveyData.map((item) => item[key]))];
    return uniqueValues.map((value) => ({ value, label: value }));
  };

  // Handle filter change
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value ? { value } : null, // Handle clear case
    }));
  };

  // Filter data when filter values change
  useEffect(() => {
    let data = surveyData;

    // Apply filters
    if (filters.MU) {
      data = data.filter((item) => item.MU === filters.MU.value);
    }
    if (filters.CU) {
      data = data.filter((item) => item.CU === filters.CU.value);
    }
    if (filters.assessed_by) {
      data = data.filter(
        (item) => item.assessed_by === filters.assessed_by.value
      );
    }
    if (filters.email) {
      data = data.filter((item) => item.email === filters.email.value);
    }
    if (filters.mode) {
      data = data.filter((item) => item.mode === filters.mode.value);
    }

    setFilteredData(data);
  }, [filters, surveyData]);

  // Function to download data as Excel
//   const downloadExcel = () => {
//     const worksheet = XLSX.utils.json_to_sheet(filteredData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "SurveyData");
//     XLSX.writeFile(workbook, "survey_data.xlsx"); // Name of the file
//   };
// Function to download data as Excel
const downloadExcel = () => {
    const excelData = filteredData.map(result => ({
      id: result.id,
      MU: result.MU,
      CU: result.CU,
      assessed_by: result.assessed_by,
      email: result.email,
      start_time: result.start_time,
      end_time: result.end_time,
      mode: result.mode,
      Q1_OP: result.Q1_OP,
      Q1_FS: result.Q1_FS,
      Q2_OP: result.Q2_OP,
      Q2_FS: result.Q2_FS,
      Q3_OP: result.Q3_OP,
      Q3_FS: result.Q3_FS,
      Q4_OP: result.Q4_OP,
      Q4_FS: result.Q4_FS,
      Q5_OP: result.Q5_OP,
      Q5_FS: result.Q5_FS,
      Q6_OP: result.Q6_OP,
      Q6_FS: result.Q6_FS,
      Q7_OP: result.Q7_OP,
      Q7_FS: result.Q7_FS,
      Q8_OP: result.Q8_OP,
      Q8_FS: result.Q8_FS,
      Q9_OP: result.Q9_OP,
      Q9_FS: result.Q9_FS,
      Q10_OP: result.Q10_OP,
      Q10_FS: result.Q10_FS,
      Q11_OP: result.Q11_OP,
      Q11_FS: result.Q11_FS,
      Q12_OP: result.Q12_OP,
      Q12_FS: result.Q12_FS,
      Q13_OP: result.Q13_OP,
      Q13_FS: result.Q13_FS,
      Q14_OP: result.Q14_OP,
      Q14_FS: result.Q14_FS,
      Q15_OP: result.Q15_OP,
      Q15_FS: result.Q15_FS,
      Q16_OP: result.Q16_OP,
      Q16_FS: result.Q16_FS,
      Q17_OP: result.Q17_OP,
      Q17_FS: result.Q17_FS,
      Q18_OP: result.Q18_OP,
      Q18_FS: result.Q18_FS,
      Q19_OP: result.Q19_OP,
      Q19_FS: result.Q19_FS,
      Q20_OP: result.Q20_OP,
      Q20_FS: result.Q20_FS,
      Q21_OP: result.Q21_OP,
      Q21_FS: result.Q21_FS,
      Q22_OP: result.Q22_OP,
      Q22_FS: result.Q22_FS,
      section1_selectedScoreFS: result.section1_selectedScoreFS,
      section1_selectedScoreOP: result.section1_selectedScoreOP,
      section2_selectedScoreFS: result.section2_selectedScoreFS,
      section2_selectedScoreOP: result.section2_selectedScoreOP,
      section3_selectedScoreFS: result.section3_selectedScoreFS,
      section3_selectedScoreOP: result.section3_selectedScoreOP,
      section4_selectedScoreFS: result.section4_selectedScoreFS,
      section4_selectedScoreOP: result.section4_selectedScoreOP,
      totalScoreFS: result.totalScoreFS,
      totalScoreOP: result.totalScoreOP,
      selectedModel: result.selected_model,
    }));
   
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SurveyData");
    XLSX.writeFile(workbook, "survey_data.xlsx"); // Name of the file
  };
  // Apply filters when button is clicked
  const handleApplyFilters = () => {
    handleFilterChange("CU", cu);
    handleFilterChange("MU", mu);
    handleFilterChange("assessed_by", assessedBy);
    handleFilterChange("email", email);
    handleFilterChange("mode", mode);
  };

  const handleGoBack = () => {
    navigate("/dashboard");
  };

  return (
    <div className="section">
      <div className="containerheader">
        <h1>Survey Data</h1>
      </div>
      {error && <p>{error}</p>}

      <div className="top-navbar">
        <h4>Filters</h4>
        <div className="filter-group">
          <div>
            <label className="label1">CU:</label>
            <input
              list="cuOptions"
              value={cu}
              onChange={(e) => setCU(e.target.value)}
              placeholder="Type CU here..."
            />
            <datalist id="cuOptions">
              {getUniqueOptions("CU").map((option, index) => (
                <option key={index} value={option.value} />
              ))}
            </datalist>
          </div>
          <div>
            <label className="label1">MU:</label>
            <input
              list="muOptions"
              value={mu}
              onChange={(e) => setMU(e.target.value)}
              placeholder="Type MU here..."
            />
            <datalist id="muOptions">
              {getUniqueOptions("MU").map((option, index) => (
                <option key={index} value={option.value} />
              ))}
            </datalist>
          </div>
          <div>
            <label className="label1">Assessed By:</label>
            <input
              list="assessedByOptions"
              value={assessedBy}
              onChange={(e) => setAssessedBy(e.target.value)}
              placeholder="Type Assessed By here..."
            />
            <datalist id="assessedByOptions">
              {getUniqueOptions("assessed_by").map((option, index) => (
                <option key={index} value={option.value} />
              ))}
            </datalist>
          </div>
          <div>
            <label className="label1">Email:</label>
            <input
              list="emailOptions"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Type Email here..."
            />
            <datalist id="emailOptions">
              {getUniqueOptions("email").map((option, index) => (
                <option key={index} value={option.value} />
              ))}
            </datalist>
          </div>
          <div>
            <label className="label1">Mode:</label>
            <input
              list="modeOptions"
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              placeholder="Type Mode here..."
            />
            <datalist id="modeOptions">
              {getUniqueOptions("mode").map((option, index) => (
                <option key={index} value={option.value} />
              ))}
            </datalist>
          </div>
          <Button label="Apply" onClick={handleApplyFilters}></Button>
          <Button label="Dashboard" onClick={handleGoBack}></Button>

        </div>
      </div>

      {/* Button to Download Excel */}
      <button onClick={downloadExcel} className="download-button">
        Download Excel
      </button>
      <div className="tablediv">
        <table border="1">
          <thead>
            <tr>
              {/* Table header as in your original code */}
              <th>id</th>
              <th>MU</th>
              <th>CU</th>
              <th>Assessed by</th>
              <th>Email</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Mode</th>
              <th>Q1_OP</th>
              <th>Q1_FS</th>
              <th>Q2_OP</th>
              <th>Q2_FS</th>
              <th>Q3_OP</th>
              <th>Q3_FS</th>
              <th>Q4_OP</th>
              <th>Q4_FS</th>
              <th>Q5_OP</th>
              <th>Q5_FS</th>
              <th>Q6_OP</th>
              <th>Q6_FS</th>
              <th>Q7_OP</th>
              <th>Q7_FS</th>
              <th>Q8_OP</th>
              <th>Q8_FS</th>
              <th>Q9_OP</th>
              <th>Q9_FS</th>
              <th>Q10_OP</th>
              <th>Q10_FS</th>
              <th>Q11_OP</th>
              <th>Q11_FS</th>
              <th>Q12_OP</th>
              <th>Q12_FS</th>
              <th>Q13_OP</th>
              <th>Q13_FS</th>
              <th>Q14_OP</th>
              <th>Q14_FS</th>
              <th>Q15_OP</th>
              <th>Q15_FS</th>
              <th>Q16_OP</th>
              <th>Q16_FS</th>
              <th>Q17_OP</th>
              <th>Q17_FS</th>
              <th>Q18_OP</th>
              <th>Q18_FS</th>
              <th>Q19_OP</th>
              <th>Q19_FS</th>
              <th>Q20_OP</th>
              <th>Q20_FS</th>
              <th>Q21_OP</th>
              <th>Q21_FS</th>
              <th>Q22_OP</th>
              <th>Q22_FS</th>
              <th>Section 1 Selected Score FS</th>
              <th>Section 1 Selected Score OP</th>
              <th>Section 2 Selected Score FS</th>
              <th>Section 2 Selected Score OP</th>
              <th>Section 3 Selected Score FS</th>
              <th>Section 3 Selected Score OP</th>
              <th>Section 4 Selected Score FS</th>
              <th>Section 4 Selected Score OP</th>
              <th>Total Score FS</th>
              <th>Total Score OP</th>
              <th>Selected Model</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((result, index) => (
              <tr key={index}>
                <td>{result.id}</td>
                <td>{result.MU}</td>
                <td>{result.CU}</td>
                <td>{result.assessed_by}</td>
                <td>{result.email}</td>
                <td>{result.start_time}</td>
                <td>{result.end_time}</td>
                <td>{result.mode}</td>
                <td>{result.Q1_OP}</td>
                <td>{result.Q1_FS}</td>
                <td>{result.Q2_OP}</td>
                <td>{result.Q2_FS}</td>
                <td>{result.Q3_OP}</td>
                <td>{result.Q3_FS}</td>
                <td>{result.Q4_OP}</td>
                <td>{result.Q4_FS}</td>
                <td>{result.Q5_OP}</td>
                <td>{result.Q5_FS}</td>
                <td>{result.Q6_OP}</td>
                <td>{result.Q6_FS}</td>
                <td>{result.Q7_OP}</td>
                <td>{result.Q7_FS}</td>
                <td>{result.Q8_OP}</td>
                <td>{result.Q8_FS}</td>
                <td>{result.Q9_OP}</td>
                <td>{result.Q9_FS}</td>
                <td>{result.Q10_OP}</td>
                <td>{result.Q10_FS}</td>
                <td>{result.Q11_OP}</td>
                <td>{result.Q11_FS}</td>
                <td>{result.Q12_OP}</td>
                <td>{result.Q12_FS}</td>
                <td>{result.Q13_OP}</td>
                <td>{result.Q13_FS}</td>
                <td>{result.Q14_OP}</td>
                <td>{result.Q14_FS}</td>
                <td>{result.Q15_OP}</td>
                <td>{result.Q15_FS}</td>
                <td>{result.Q16_OP}</td>
                <td>{result.Q16_FS}</td>
                <td>{result.Q17_OP}</td>
                <td>{result.Q17_FS}</td>
                <td>{result.Q18_OP}</td>
                <td>{result.Q18_FS}</td>
                <td>{result.Q19_OP}</td>
                <td>{result.Q19_FS}</td>
                <td>{result.Q20_OP}</td>
                <td>{result.Q20_FS}</td>
                <td>{result.Q21_OP}</td>
                <td>{result.Q21_FS}</td>
                <td>{result.Q22_OP}</td>
                <td>{result.Q22_FS}</td>
                <td>{result.section1_selectedScoreFS}</td>
                <td>{result.section1_selectedScoreOP}</td>
                <td>{result.section2_selectedScoreFS}</td>
                <td>{result.section2_selectedScoreOP}</td>
                <td>{result.section3_selectedScoreFS}</td>
                <td>{result.section3_selectedScoreOP}</td>
                <td>{result.section4_selectedScoreFS}</td>
                <td>{result.section4_selectedScoreOP}</td>
                <td>{result.totalScoreFS}</td>
                <td>{result.totalScoreOP}</td>
                <td>{result.selected_model}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Data;
