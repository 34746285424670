// import React, { useState } from "react"; // Import useState
// import "./ResultsPage.css";
// import "./Survey.css";
// import Button from "./Button";
// import Navbar from "./Navbar"; // Import Navbar
// import PieChartComponent from "./Components/PieChartComponent"; // Import the new PieChartComponent
// import { useNavigate } from "react-router-dom"; // Import useNavigate at the top
// import config from './config'

// const ResultsPage = ({
//   sections,
//   currentSectionIndex,
//   setCurrentSectionIndex,
//   onNavigateToSurvey,
//   storeData,
//   formData,
// }) => {
//   const { mu, cu, assessedBy, email } = formData;
//   const navigate = useNavigate(); // Initialize the navigate function

//   const CustomTooltip = ({ active, payload, totalWeight }) => {
//     if (active && payload && payload.length) {
//       // Getting both FIVE STARS and OPEX data values from payload
//       const fiveStarsData = payload.find((p) => p.name === "FIVE STARS");
//       const opexData = payload.find((p) => p.name === "OPEX");

//       console.log("fiveStarsData", fiveStarsData);
//       console.log("opexData", opexData);

//       // Ensure values exist, default to 0 if not
//       const fiveStarsValue = fiveStarsData ? fiveStarsData.value : 0;
//       const opexValue = opexData ? opexData.value : 0;

//       // Calculate total value
//       const totalValue = fiveStarsValue + opexValue;

//       // Get the current hovered slice's value and name
//       const { name, value } = payload[0];

//       // Calculate the percentage for the hovered slice
//       const percentage =
//         totalValue > 0 ? ((value / totalWeight) * 100).toFixed(2) : 0;

//       return (
//         <div className="custom-tooltip">
//           <p style={{ color: name === "FIVE STARS" ? "#0088FE" : "#FF0000" }}>
//             {`${name}: ${percentage}%`}
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   const calculateScores = (section) => {
//     let totalWeight = 0;
//     let selectedScoreOP = 0;
//     let selectedScoreFS = 0;

//     section.questions.forEach((question) => {
//       question.options.forEach((option) => {
//         if (option.checked) {
//           totalWeight += option.weight || 0;
//           if (option.type === "OP") {
//             selectedScoreOP += option.weight || 0;
//           } else if (option.type === "FS") {
//             selectedScoreFS += option.weight || 0;
//           }
//         }
//       });
//     });

//     return { totalWeight, selectedScoreOP, selectedScoreFS };
//   };

//   const calculateSectionData = (sections) => {
//     return sections.map((section) => {
//       const { selectedScoreOP, selectedScoreFS, totalWeight } =
//         calculateScores(section);
//       return {
//         title: section.title,
//         selectedScoreOP,
//         selectedScoreFS,
//         totalWeight,
//       };
//     });
//   };

//   const selectedData = storeData(); // Call storeData to access the data
//   const filledData = formData(); // Call storeData to access the data
//   const sectionData = calculateSectionData(sections); // Call to get section data

//   const calculateOverallScores = () => {
//     let totalScoreOP = 0;
//     let totalScoreFS = 0;

//     sectionData.forEach((section) => {
//       totalScoreOP += section.selectedScoreOP;
//       totalScoreFS += section.selectedScoreFS;
//     });

//     return { totalScoreOP, totalScoreFS };
//   };

//   // Get the total scores for all sections
//   const { totalScoreOP, totalScoreFS } = calculateOverallScores();

//   // Determine the higher score
//   //const higherScore = totalScoreFS > totalScoreOP ? 'FIVE STARS' : 'OPEX';
//   const higherScore =
//     totalScoreFS > totalScoreOP
//       ? "FIVE STARS"
//       : totalScoreFS < totalScoreOP
//       ? "OPEX"
//       : "FIVE STARS/OPEX"; // Added condition for equality

//   const handleSubmit = () => {
//     console.log("sectionData", sectionData);
//     console.log("totalScoreOsddfdfP");

//     const { totalScoreOP, totalScoreFS } = calculateOverallScores();
//     const selectedModel = higherScore; // Set selected model to higher score

//     const data = {
//       sections: sectionData,
//       overallScores: {
//         totalScoreOP,
//         totalScoreFS,
//       },
//       selectedData,
//       filledData,
//       selectedModel,
//     };
//     console.log("data", data); // Log the selected data

//     // Send data to backend
//     fetch(`${config.API_BASE_URL}/save-results`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     })
//       .then((response) =>
//         response.json())
//       .then((data) => {
//         console.log("Success:", data);
//         // navigate('/thank-you'); // Redirect to /thank-you route
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };

//   return (
//     <div className="results-container survey-container">
//       <div className="containerheader">
//         <h2>SALES OPERATIONAL MODEL SELECTION</h2>
//       </div>
//       <Navbar
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={onNavigateToSurvey} // Pass the navigation function
//       />

//       <div className="custom-hr"></div>

//       <div className="charts-row">
//         {sectionData.map((section, sectionIndex) => {
//           const { selectedScoreOP, selectedScoreFS } = section; // Destructure title and scores

//           return (
//             <div key={sectionIndex} className="section-chart">
//               <h3>{section.title}</h3>
//               <PieChartComponent
//                 data={[
//                   { name: "FIVE STARS", value: selectedScoreFS },
//                   { name: "OPEX", value: selectedScoreOP },
//                 ]}
//                 width={300}
//                 height={250}
//                 outerRadius="75%"
//                 innerRadius="40%"
//                 customTooltip={
//                   <CustomTooltip
//                     totalWeight={selectedScoreOP + selectedScoreFS}
//                   />
//                 }
//               />
//               <div className="section-details">
//                 <p>Selected Score (OPEX): {selectedScoreOP}</p>
//                 <p>Selected Score (FIVE STARS): {selectedScoreFS}</p>
//               </div>
//             </div>
//           );
//         })}
//       </div>

//       {/* Display the overall result */}
//       <div className="overall-result">
//         <h3>Overall Higher Score: {higherScore}</h3>
//         <p>Total Score (FIVE STARS): {totalScoreFS}</p>
//         <p>Total Score (OPEX): {totalScoreOP}</p>
//       </div>

//       {/* Submit Button */}
//       <Button label="Submit" onClick={handleSubmit}></Button>
//     </div>
//   );
// };

// export default ResultsPage;

import React, { useState, useRef } from "react"; // Import useState
import "./ResultsPage.css";
import "./Survey.css";
import Button from "./Button";
import Navbar from "./Navbar"; // Import Navbar
import PieChartComponent from "./Components/PieChartComponent"; // Import the new PieChartComponent
import { useNavigate } from "react-router-dom"; // Import useNavigate at the top
import config from "./config";
import html2pdf from "html2pdf.js";
import logo from "./logo.png";
import Loader from "./Components/Loader"; // Import the Loader component

const ResultsPage = ({
  sections,
  currentSectionIndex,
  setCurrentSectionIndex,
  onNavigateToSurvey,
  storeData,
  formData,
}) => {
  const { mu, cu, assessedBy, email } = formData;
  const navigate = useNavigate(); // Initialize the navigate function

  const pdfRef = useRef();

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false); // Track PDF generation state
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission success

  const generatePDF = () => {
    window.scrollTo(0, 0);
    // Prevent further execution if loading or submitted
    if (isLoading || isSubmitted) {
      return; // Early return to prevent multiple submissions
    }
    setIsLoading(true); // Set loading state to true
    setIsGeneratingPDF(true); // Set flag to true
    const element = pdfRef.current;
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: "generated.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 0.8 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    // html2pdf().from(element).set(opt).save().then(() => {
    //   setIsGeneratingPDF(false); // Reset flag after PDF generation
    // });
    html2pdf()
      .from(element)
      .set(opt)
      .outputPdf("blob") // Use 'blob' to get PDF data as a blob
      .then((pdfBlob) => {
        handleSubmit(pdfBlob); // Send the generated PDF to the backend
        setIsGeneratingPDF(false); // Reset flag after PDF generation
        setIsLoading(false); // Reset loading state after PDF generation
      });
  };

  // Function to send the generated PDF to the backend
  const sendPDFToBackend = (pdfBlob) => {
    const formData = new FormData(); // Create a FormData object
    formData.append("file", pdfBlob, "generated.pdf"); // Append the PDF blob with a filename

    // Send the FormData with the PDF blob to the backend
    fetch(`${config.API_BASE_URL}/upload-pdf`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("PDF upload success:", data);
      })
      .catch((error) => {
        console.error("Error uploading PDF:", error);
      });
  };

  const CustomTooltip = ({ active, payload, totalWeight }) => {
    if (active && payload && payload.length) {
      // Getting both FIVE STARS and OPEX data values from payload
      const fiveStarsData = payload.find((p) => p.name === "FIVE STARS");
      const opexData = payload.find((p) => p.name === "OPEX");

      // console.log("fiveStarsData", fiveStarsData);
      // console.log("opexData", opexData);

      // Ensure values exist, default to 0 if not
      const fiveStarsValue = fiveStarsData ? fiveStarsData.value : 0;
      const opexValue = opexData ? opexData.value : 0;

      // Calculate total value
      const totalValue = fiveStarsValue + opexValue;

      // Get the current hovered slice's value and name
      const { name, value } = payload[0];

      // Calculate the percentage for the hovered slice
      const percentage =
        totalValue > 0 ? ((value / totalWeight) * 100).toFixed(2) : 0;

      return (
        <div className="custom-tooltip">
          <p style={{ color: name === "FIVE STARS" ? "#0088FE" : "#FF0000" }}>
            {`${name}: ${percentage}%`}
          </p>
        </div>
      );
    }
    return null;
  };

  const calculateScores = (section) => {
    let totalWeight = 0;
    let selectedScoreOP = 0;
    let selectedScoreFS = 0;

    section.questions.forEach((question) => {
      question.options.forEach((option) => {
        if (option.checked) {
          totalWeight += option.weight || 0;
          if (option.type === "OP") {
            selectedScoreOP += option.weight || 0;
          } else if (option.type === "FS") {
            selectedScoreFS += option.weight || 0;
          }
        }
      });
    });

    return { totalWeight, selectedScoreOP, selectedScoreFS };
  };

  const calculateSectionData = (sections) => {
    return sections.map((section) => {
      const { selectedScoreOP, selectedScoreFS, totalWeight } =
        calculateScores(section);
      return {
        title: section.title,
        selectedScoreOP,
        selectedScoreFS,
        totalWeight,
      };
    });
  };

  const selectedData = storeData(); // Call storeData to access the data
  const filledData = formData(); // Call storeData to access the data
  const sectionData = calculateSectionData(sections); // Call to get section data

  const calculateOverallScores = () => {
    let totalScoreOP = 0;
    let totalScoreFS = 0;

    sectionData.forEach((section) => {
      totalScoreOP += section.selectedScoreOP;
      totalScoreFS += section.selectedScoreFS;
    });

    return { totalScoreOP, totalScoreFS };
  };

  // Get the total scores for all sections
  const { totalScoreOP, totalScoreFS } = calculateOverallScores();

  // Determine the higher score
  //const higherScore = totalScoreFS > totalScoreOP ? 'FIVE STARS' : 'OPEX';
  const higherScore =
    totalScoreFS > totalScoreOP
      ? "FIVE STARS"
      : totalScoreFS < totalScoreOP
      ? "OPEX"
      : "FIVE STARS/OPEX"; // Added condition for equality

  const handleSubmit = (pdfBlob) => {
    // console.log("sectionData", sectionData);
    // console.log("totalScoreOsddfdfP");

    const { totalScoreOP, totalScoreFS } = calculateOverallScores();
    const selectedModel = higherScore; // Set selected model to higher score

     // Create a timestamp
  //const submissionTimestamp = new Date().toISOString(); // Format the timestamp as needed
  const submissionTimestamp = new Date().toLocaleString(); // Format the timestamp as needed
  const mode = 'test';

    const data = {
      sections: sectionData,
      overallScores: {
        totalScoreOP,
        totalScoreFS,
      },
      selectedData,
      filledData,
      selectedModel,
      submissionTimestamp, // Add the timestamp here
      mode,
    };
     console.log("data", data); // Log the selected data
    const formData = new FormData(); // Create a FormData object
    formData.append("file", pdfBlob, "generated.pdf"); // Append the PDF blob with a filename
    formData.append("data", JSON.stringify(data)); // Append the PDF blob with a filename

    // Send data to backend
    setIsSubmitted(true)
        
    fetch(`${config.API_BASE_URL}/save-results`, {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Success:", data);
        navigate("/thank-you");
      })
      .catch((error) => {
        console.error("Error:", error);
        isLoading(false)
      });
  };

  return (
    <div ref={pdfRef} id="pdf-content" className="">
      <div className="containerheader">
        <h2>SALES OPERATIONAL MODEL SELECTION</h2>
      </div>
      {!isGeneratingPDF && (
        <Navbar
          sections={sections}
          currentSectionIndex={currentSectionIndex}
          setCurrentSectionIndex={onNavigateToSurvey} // Pass the navigation function
          style={{ display: isGeneratingPDF ? "none" : "block" }}
        />
      )}

      <div className="custom-hr"></div>

      <div className="charts-row">
        {sectionData.map((section, sectionIndex) => {
          const { selectedScoreOP, selectedScoreFS } = section; // Destructure title and scores

          return (
            <div key={sectionIndex} className="section-chart">
              <h3>{section.title}</h3>
              <PieChartComponent
                data={[
                  { name: "FIVE STARS", value: selectedScoreFS },
                  { name: "OPEX", value: selectedScoreOP },
                ]}
                width={300}
                height={250}
                outerRadius="75%"
                innerRadius="40%"
                customTooltip={
                  <CustomTooltip
                    totalWeight={selectedScoreOP + selectedScoreFS}
                  />
                }
              />
              {/* {!isGeneratingPDF && (
                <div className="section-details">
                  <p>Selected Score (OPEX): {selectedScoreOP}</p>
                  <p>Selected Score (FIVE STARS): {selectedScoreFS}</p>
                </div>
              )} */}
            </div>
          );
        })}
      </div>

      {/* Display the overall result */}
      <div className="overall-result">
        <h1 id="higherScoreTitle">Best fit:</h1>
        <p className="higherScore">{higherScore}</p>
        {/* {!isGeneratingPDF && <p>Total Score (FIVE STARS): {totalScoreFS}</p>}
        {!isGeneratingPDF && <p>Total Score (OPEX): {totalScoreOP}</p>} */}
      </div>

      {/* Submit Button */}
      {!isGeneratingPDF && !isSubmitted && (
        <div className="button-container">
          <div className="button-center">
            <Button
              label={isLoading ? "Generating..." : "Submit"}
              onClick={generatePDF}
              disabled={isLoading} // Disable the button if loading
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultsPage;
