// import React, { useState, useEffect } from "react";
// import sectionsData from "./section.json";
// import "./Survey.css";
// import parse from "html-react-parser";
// import Button from "./Button";
// import ResultsPage from "./ResultsPage";
// import Navbar from "./Navbar";
// import { useLocation } from "react-router-dom";

// function Survey() {
//   const [sections, setSections] = useState(sectionsData.sections);
//   const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
//   const [questionErrors, setQuestionErrors] = useState({});
//   const [showResults, setShowResults] = useState(false);

//   const location = useLocation();
//   const [formData, setFormData] = useState({
//     mu: "",
//     cu: "",
//     assessedBy: "",
//     email: "",
//   });

//   useEffect(() => {
//     if (location.state) {
//       // Update state with the formData passed via navigation
//       setFormData(location.state);
//     }
//   }, [location.state]);

//   // Function to randomize options for a given section's questions
//   const randomizeOptions = (questions) => {
//     return questions.map((question) => {
//       const shuffledOptions = [...question.options].sort(
//         () => Math.random() - 0.5
//       );
//       return { ...question, options: shuffledOptions };
//     });
//   };

//   // Initialize sections with randomized options when the component loads
//   useEffect(() => {
//     const randomizedSections = sections.map((section) => {
//       const randomizedQuestions = randomizeOptions(section.questions);
//       return { ...section, questions: randomizedQuestions };
//     });
//     setSections(randomizedSections);
//   }, []); // This runs once when the component is first loaded

//   const handleOptionChange = (sectionIndex, questionId, optionIndex) => {
//     const updatedSections = sections.map((section, idx) => {
//       if (idx === sectionIndex) {
//         const updatedQuestions = section.questions.map((question) => {
//           if (question.id === questionId) {
//             const updatedOptions = question.options.map((option, idx2) => ({
//               ...option,
//               checked: idx2 === optionIndex ? !option.checked : option.checked,
//             }));
//             return { ...question, options: updatedOptions };
//           }
//           return question;
//         });
//         return { ...section, questions: updatedQuestions };
//       }
//       return section;
//     });
//     setSections(updatedSections);
//     setQuestionErrors({});
//   };

//   const validateOptions = () => {
//     const currentQuestions = sections[currentSectionIndex].questions;
//     let isValid = true;
//     const errors = {};

//     currentQuestions.forEach((question) => {
//       const isQuestionValid = question.options.some((option) => option.checked);
//       if (!isQuestionValid) {
//         errors[question.id] = "Please select at least one option.";
//         isValid = false;
//       }
//     });

//     setQuestionErrors(errors);
//     return isValid;
//   };

//   const handleNextSection = () => {
//     if (validateOptions()) {
//       storeData(); // Store data before navigating to the next section
//       if (currentSectionIndex < sections.length - 1) {
//         setCurrentSectionIndex(currentSectionIndex + 1);
//         console.log("currentSectionIndex", currentSectionIndex);
//       }
//     }
//   };

//   const storeData = () => {
//     const selectedOptionsData = {};
//     sections.forEach((section) => {
//       section.questions.forEach((question) => {
//         question.options.forEach((option, index) => {
//           // Determine the option type (OP or FS)
//           const optionType =
//             option.type === "OP" ? "OP" : option.type === "FS" ? "FS" : "";

//           // Generate the variable name based on the question id and option type
//           const variableName = `Q${question.id}_${optionType}`;

//           // If the option is checked, use its weight; otherwise, set to 0
//           selectedOptionsData[variableName] = option.checked
//             ? option.weight || 0
//             : 0;
//         });
//       });
//     });

//     console.log("Selected Options:", selectedOptionsData);
//     return selectedOptionsData;
//   };

//   const intropageData = () => {
//     return formData;
//   };

//   // Updated calculateScores function
//   const calculateScores = () => {
//     let totalWeight = 0;
//     let selectedScoreOP = 0;
//     let selectedScoreFS = 0;

//     sections[currentSectionIndex].questions.forEach((question) => {
//       question.options.forEach((option) => {
//         // if (option.checked.weight) {
//         //     totalWeight += option.weight; // Add all weights
//         // }

//         if (option.checked) {
//           totalWeight += option.weight; // Add all weights
//           // Add to the appropriate score based on type
//           if (option.type === "OP") {
//             selectedScoreOP += option.weight || 0;
//           } else if (option.type === "FS") {
//             selectedScoreFS += option.weight || 0;
//           }
//         }
//       });
//     });

//     return { totalWeight, selectedScoreOP, selectedScoreFS };
//   };

//   const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores();

//   const handleViewResults = () => {
//     storeData(); // Store data before viewing results
//     const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores(); // Get the calculated scores
//     setShowResults(true); // Show the results page
//   };

//   const handleNavigateToSurvey = (index) => {
//     setShowResults(false); // Hide ResultsPage
//     setCurrentSectionIndex(index); // Navigate to the selected section
//   };

//   // If showing results, display the results page
//   if (showResults) {
//     return (
//       <ResultsPage
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={setCurrentSectionIndex}
//         onNavigateToSurvey={handleNavigateToSurvey} // Pass the function to ResultsPage
//         storeData={storeData} // Pass the storeData function as a prop
//         formData={intropageData} // Pass formData here
//       />
//     );
//   }

//   const hasError = Object.keys(questionErrors).length > 0;

//   return (
//     <div className="survey-container">
//       <div className="containerheader">
//         <p>Self Assessment</p>
//         <h2>Sales Operational Model</h2>
//       </div>

//       <Navbar
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={handleNavigateToSurvey} // Update the function passed here
//       />

//       <div className="section">
//         <div className="questioninstruction">
//           Check the boxes that better match your market. If both options match
//           your reality in a relevant way (e.g., different channels or regions),
//           you can check both.
//         </div>
//         <div className="custom-hr"></div>
//         <h2 className="title">{sections[currentSectionIndex].title}</h2>
//         <div className="custom-hr"></div>

//         {hasError && (
//           <p className="error">
//             Please select at least one option for each question.
//           </p>
//         )}

//         {sections[currentSectionIndex].questions.map((question) => (
//           <div
//             key={question.id}
//             className={`question ${
//               questionErrors[question.id] ? "option-error" : ""
//             }`}
//           >
//             <div className="question-text">{parse(question.text)}</div>
//             {question.options.map((option, index) => (
//               <label
//                 key={index}
//                 className={`option ${option.checked ? "selected" : ""}`}
//               >
//                 <input
//                   type="checkbox"
//                   checked={option.checked}
//                   onChange={() =>
//                     handleOptionChange(currentSectionIndex, question.id, index)
//                   }
//                 />
//                 {parse(option.text)}
//               </label>
//             ))}
//           </div>
//         ))}
//         {/* <div className="score-summary">
//           Selected Score OP: {selectedScoreOP}, Selected Score FS:{" "}
//           {selectedScoreFS}, Total Weight: {totalWeight}, Ratio:{" "}
//           {totalWeight
//             ? ((selectedScoreOP + selectedScoreFS) / totalWeight).toFixed(2)
//             : 0}
//         </div> */}
//         {currentSectionIndex === sections.length - 1 ? (
//           <Button label="View Results" onClick={handleViewResults}></Button>
//         ) : (
//           <Button label="Next Section" onClick={handleNextSection}></Button>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Survey;

// import React, { useState, useEffect } from "react";
// import sectionsData from "./section.json";
// import "./Survey.css";
// import parse from "html-react-parser";
// import Button from "./Button";
// import ResultsPage from "./ResultsPage";
// import Navbar from "./Navbar";
// import { useLocation } from "react-router-dom";

// function Survey() {
//   const [sections, setSections] = useState(sectionsData.sections);
//   const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
//   const [questionErrors, setQuestionErrors] = useState({});
//   const [showResults, setShowResults] = useState(false);

//   const location = useLocation();
//   const [formData, setFormData] = useState({
//     mu: "",
//     cu: "",
//     assessedBy: "",
//     email: "",
//   });

//   useEffect(() => {
//     if (location.state) {
//       // Update state with the formData passed via navigation
//       setFormData(location.state);
//     }
//   }, [location.state]);

//   // Function to randomize options for a given section's questions
//   const randomizeOptions = (questions) => {
//     return questions.map((question) => {
//       const shuffledOptions = [...question.options].sort(
//         () => Math.random() - 0.5
//       );
//       return { ...question, options: shuffledOptions };
//     });
//   };

//   // Initialize sections with randomized options when the component loads
//   useEffect(() => {
//     const randomizedSections = sections.map((section) => {
//       const randomizedQuestions = randomizeOptions(section.questions);
//       return { ...section, questions: randomizedQuestions };
//     });
//     setSections(randomizedSections);
//   }, []); // This runs once when the component is first loaded

//   const handleOptionChange = (sectionIndex, questionId, optionIndex) => {
//     const updatedSections = sections.map((section, idx) => {
//       if (idx === sectionIndex) {
//         const updatedQuestions = section.questions.map((question) => {
//           if (question.id === questionId) {
//             const updatedOptions = question.options.map((option, idx2) => ({
//               ...option,
//               checked: idx2 === optionIndex ? !option.checked : option.checked,
//             }));
//             return { ...question, options: updatedOptions };
//           }
//           return question;
//         });
//         return { ...section, questions: updatedQuestions };
//       }
//       return section;
//     });
//     setSections(updatedSections);
//     setQuestionErrors({});
//   };

//   // Validate the entire survey when the user clicks "View Results"
//   const validateAllSections = () => {
//     let isValid = true;
//     const errors = {};

//     sections.forEach((section) => {
//       section.questions.forEach((question) => {
//         const isQuestionValid = question.options.some(
//           (option) => option.checked
//         );
//         if (!isQuestionValid) {
//           errors[question.id] = "Please select at least one option.";
//           isValid = false;
//         }
//       });
//     });

//     setQuestionErrors(errors);
//     return isValid;
//   };

//   const handleNextSection = () => {
//     setCurrentSectionIndex(currentSectionIndex + 1); // Move to the next section
//     setQuestionErrors({});
//   };

//   const handleViewResults = () => {
//     const isSurveyValid = validateAllSections(); // Validate the entire survey
//     if (isSurveyValid) {
//       storeData(); // Store data before viewing results
//       const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores(); // Get the calculated scores
//       setShowResults(true); // Show the results page
//     }
//   };

//   const storeData = () => {
//     const selectedOptionsData = {};
//     sections.forEach((section) => {
//       section.questions.forEach((question) => {
//         question.options.forEach((option, index) => {
//           // Determine the option type (OP or FS)
//           const optionType =
//             option.type === "OP" ? "OP" : option.type === "FS" ? "FS" : "";

//           // Generate the variable name based on the question id and option type
//           const variableName = `Q${question.id}_${optionType}`;

//           // If the option is checked, use its weight; otherwise, set to 0
//           selectedOptionsData[variableName] = option.checked
//             ? option.weight || 0
//             : 0;
//         });
//       });
//     });

//     console.log("Selected Options:", selectedOptionsData);
//     return selectedOptionsData;
//   };

//   const intropageData = () => {
//     return formData;
//   };

//   // Updated calculateScores function
//   const calculateScores = () => {
//     let totalWeight = 0;
//     let selectedScoreOP = 0;
//     let selectedScoreFS = 0;

//     sections[currentSectionIndex].questions.forEach((question) => {
//       question.options.forEach((option) => {
//         if (option.checked) {
//           totalWeight += option.weight; // Add all weights
//           // Add to the appropriate score based on type
//           if (option.type === "OP") {
//             selectedScoreOP += option.weight || 0;
//           } else if (option.type === "FS") {
//             selectedScoreFS += option.weight || 0;
//           }
//         }
//       });
//     });

//     return { totalWeight, selectedScoreOP, selectedScoreFS };
//   };

//   const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores();

//   const handleNavigateToSurvey = (index) => {
//     setShowResults(false); // Hide ResultsPage
//     setCurrentSectionIndex(index); // Navigate to the selected section
//   };

//   // If showing results, display the results page
//   if (showResults) {
//     return (
//       <ResultsPage
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={setCurrentSectionIndex}
//         onNavigateToSurvey={handleNavigateToSurvey} // Pass the function to ResultsPage
//         storeData={storeData} // Pass the storeData function as a prop
//         formData={intropageData} // Pass formData here
//       />
//     );
//   }

//   const hasError = Object.keys(questionErrors).length > 0;

//   return (
//     <div className="survey-container">
//       <div className="containerheader">
//         <p>Self Assessment</p>
//         <h2>Sales Operational Model</h2>
//       </div>

//       <Navbar
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={handleNavigateToSurvey} // Update the function passed here
//       />

//       <div className="section">
//         <div className="questioninstruction">
//           Check the boxes that better match your market. If both options match
//           your reality in a relevant way (e.g., different channels or regions),
//           you can check both.
//         </div>
//         <div className="custom-hr"></div>
//         <h2 className="title">{sections[currentSectionIndex].title}</h2>
//         <div className="custom-hr"></div>

//         {hasError && (
//           <p className="error">
//             Please select at least one option for each question.
//           </p>
//         )}

//         {sections[currentSectionIndex].questions.map((question) => (
//           <div
//             key={question.id}
//             className={`question ${
//               questionErrors[question.id] ? "option-error" : ""
//             }`}
//           >
//             <div className="question-text">{parse(question.text)}</div>
//             {question.options.map((option, index) => (
//               <label
//                 key={index}
//                 className={`option ${option.checked ? "selected" : ""}`}
//               >
//                 <input
//                   type="checkbox"
//                   checked={option.checked}
//                   onChange={() =>
//                     handleOptionChange(currentSectionIndex, question.id, index)
//                   }
//                 />
//                 {parse(option.text)}
//               </label>
//             ))}
//           </div>
//         ))}

//         {currentSectionIndex === sections.length - 1 ? (
//           <Button label="View Results" onClick={handleViewResults}></Button>
//         ) : (
//           <Button label="Next Section" onClick={handleNextSection}></Button>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Survey;

// import React, { useState, useEffect } from "react";
// import sectionsData from "./section.json";
// import "./Survey.css";
// import parse from "html-react-parser";
// import Button from "./Button";
// import ResultsPage from "./ResultsPage";
// import Navbar from "./Navbar";
// import { useLocation } from "react-router-dom";

// function Survey() {
//   const [sections, setSections] = useState(sectionsData.sections);
//   const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
//   const [questionErrors, setQuestionErrors] = useState({});
//   const [errorSectionName, setErrorSectionName] = useState(null); // For showing section name in error
//   const [showResults, setShowResults] = useState(false);

//   const location = useLocation();
//   const [formData, setFormData] = useState({
//     mu: "",
//     cu: "",
//     assessedBy: "",
//     email: "",
//   });

//   useEffect(() => {
//     if (location.state) {
//       // Update state with the formData passed via navigation
//       setFormData(location.state);
//     }
//   }, [location.state]);

//   // Function to randomize options for a given section's questions
//   const randomizeOptions = (questions) => {
//     return questions.map((question) => {
//       const shuffledOptions = [...question.options].sort(
//         () => Math.random() - 0.5
//       );
//       return { ...question, options: shuffledOptions };
//     });
//   };

//   // Initialize sections with randomized options when the component loads
//   useEffect(() => {
//     const randomizedSections = sections.map((section) => {
//       const randomizedQuestions = randomizeOptions(section.questions);
//       return { ...section, questions: randomizedQuestions };
//     });
//     setSections(randomizedSections);
//   }, []); // This runs once when the component is first loaded

//   const handleOptionChange = (sectionIndex, questionId, optionIndex) => {
//     const updatedSections = sections.map((section, idx) => {
//       if (idx === sectionIndex) {
//         const updatedQuestions = section.questions.map((question) => {
//           if (question.id === questionId) {
//             const updatedOptions = question.options.map((option, idx2) => ({
//               ...option,
//               checked: idx2 === optionIndex ? !option.checked : option.checked,
//             }));
//             return { ...question, options: updatedOptions };
//           }
//           return question;
//         });
//         return { ...section, questions: updatedQuestions };
//       }
//       return section;
//     });
//     setSections(updatedSections);
//     setQuestionErrors({});
//   };

//   // Validate the entire survey when the user clicks "View Results"
//   const validateAllSections = () => {
//     let isValid = true;
//     const errors = {};

//     // Loop through each section and question to check if all are answered
//     sections.forEach((section, sectionIndex) => {
//       section.questions.forEach((question) => {
//         const isQuestionValid = question.options.some(
//           (option) => option.checked
//         );
//         if (!isQuestionValid) {
//           errors[question.id] = "Please select at least one option.";
//           setErrorSectionName(section.title); // Capture the section title with the first error
//           isValid = false;
//         }
//       });
//     });

//     setQuestionErrors(errors);

//     // Scroll to the top if there's an error
//     if (!isValid) {
//       window.scrollTo(0, 0);
//     }

//     return isValid;
//   };

//   const handleNextSection = () => {
//     setCurrentSectionIndex(currentSectionIndex + 1); // Move to the next section
//     setQuestionErrors({});
//     setErrorSectionName(null); // Reset the section error when moving to the next section
//   };

//   const handleViewResults = () => {
//     const isSurveyValid = validateAllSections(); // Validate the entire survey
//     if (isSurveyValid) {
//       storeData(); // Store data before viewing results
//       const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores(); // Get the calculated scores
//       setShowResults(true); // Show the results page
//     }
//   };

//   const storeData = () => {
//     const selectedOptionsData = {};
//     sections.forEach((section) => {
//       section.questions.forEach((question) => {
//         question.options.forEach((option, index) => {
//           // Determine the option type (OP or FS)
//           const optionType =
//             option.type === "OP" ? "OP" : option.type === "FS" ? "FS" : "";

//           // Generate the variable name based on the question id and option type
//           const variableName = `Q${question.id}_${optionType}`;

//           // If the option is checked, use its weight; otherwise, set to 0
//           selectedOptionsData[variableName] = option.checked
//             ? option.weight || 0
//             : 0;
//         });
//       });
//     });

//     console.log("Selected Options:", selectedOptionsData);
//     return selectedOptionsData;
//   };

//   const intropageData = () => {
//     return formData;
//   };

//   // Updated calculateScores function
//   const calculateScores = () => {
//     let totalWeight = 0;
//     let selectedScoreOP = 0;
//     let selectedScoreFS = 0;

//     sections[currentSectionIndex].questions.forEach((question) => {
//       question.options.forEach((option) => {
//         if (option.checked) {
//           totalWeight += option.weight; // Add all weights
//           // Add to the appropriate score based on type
//           if (option.type === "OP") {
//             selectedScoreOP += option.weight || 0;
//           } else if (option.type === "FS") {
//             selectedScoreFS += option.weight || 0;
//           }
//         }
//       });
//     });

//     return { totalWeight, selectedScoreOP, selectedScoreFS };
//   };

//   const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores();

//   const handleNavigateToSurvey = (index) => {
//     setShowResults(false); // Hide ResultsPage
//     setCurrentSectionIndex(index); // Navigate to the selected section
//   };

//   // If showing results, display the results page
//   if (showResults) {
//     return (
//       <ResultsPage
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={setCurrentSectionIndex}
//         onNavigateToSurvey={handleNavigateToSurvey} // Pass the function to ResultsPage
//         storeData={storeData} // Pass the storeData function as a prop
//         formData={intropageData} // Pass formData here
//       />
//     );
//   }

//   const hasError = Object.keys(questionErrors).length > 0;

//   return (
//     <div className="survey-container">
//       <div className="containerheader">
//         <p>Self Assessment</p>
//         <h2>Sales Operational Model</h2>
//       </div>

//       <Navbar
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={handleNavigateToSurvey} // Update the function passed here
//       />

//       <div className="section">
//         <div className="questioninstruction">
//           Check the boxes that better match your market. If both options match
//           your reality in a relevant way (e.g., different channels or regions),
//           you can check both.
//         </div>
//         <div className="custom-hr"></div>
//         <h2 className="title">{sections[currentSectionIndex].title}</h2>
//         <div className="custom-hr"></div>

//         {hasError && (
//           <p className="error">
//             {`Please select at least one option in the section: ${errorSectionName}`}
//           </p>
//         )}

//         {sections[currentSectionIndex].questions.map((question) => (
//           <div
//             key={question.id}
//             className={`question ${
//               questionErrors[question.id] ? "option-error" : ""
//             }`}
//           >
//             <div className="question-text">{parse(question.text)}</div>
//             {question.options.map((option, index) => (
//               <label
//                 key={index}
//                 className={`option ${option.checked ? "selected" : ""}`}
//               >
//                 <input
//                   type="checkbox"
//                   checked={option.checked}
//                   onChange={() =>
//                     handleOptionChange(currentSectionIndex, question.id, index)
//                   }
//                 />
//                 {parse(option.text)}
//               </label>
//             ))}
//           </div>
//         ))}

//         {currentSectionIndex === sections.length - 1 ? (
//           <Button label="View Results" onClick={handleViewResults}></Button>
//         ) : (
//           <Button label="Next Section" onClick={handleNextSection}></Button>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Survey;


// import React, { useState, useEffect } from "react";
// import sectionsData from "./section.json";
// import "./Survey.css";
// import parse from "html-react-parser";
// import Button from "./Button";
// import ResultsPage from "./ResultsPage";
// import Navbar from "./Navbar";
// import { useLocation } from "react-router-dom";
// import logo from './logo.png'

// function Survey() {
//   const [sections, setSections] = useState(sectionsData.sections);
//   const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
//   const [questionErrors, setQuestionErrors] = useState({});
//   const [invalidSections, setInvalidSections] = useState([]); // List of sections with errors
//   const [showResults, setShowResults] = useState(false);

//   const location = useLocation();
//   const [formData, setFormData] = useState({
//     mu: "",
//     cu: "",
//     assessedBy: "",
//     email: "",
//   });

//   useEffect(() => {
//     if (location.state) {
//       setFormData(location.state);
//     }
//   }, [location.state]);

//   // Function to randomize options for a given section's questions
//   const randomizeOptions = (questions) => {
//     return questions.map((question) => {
//       const shuffledOptions = [...question.options].sort(
//         () => Math.random() - 0.5
//       );
//       return { ...question, options: shuffledOptions };
//     });
//   };

//   // Initialize sections with randomized options when the component loads
//   useEffect(() => {
//     const randomizedSections = sections.map((section) => {
//       const randomizedQuestions = randomizeOptions(section.questions);
//       return { ...section, questions: randomizedQuestions };
//     });
//     setSections(randomizedSections);
//   }, []); // This runs once when the component is first loaded

//   const handleOptionChange = (sectionIndex, questionId, optionIndex) => {
//     const updatedSections = sections.map((section, idx) => {
//       if (idx === sectionIndex) {
//         const updatedQuestions = section.questions.map((question) => {
//           if (question.id === questionId) {
//             const updatedOptions = question.options.map((option, idx2) => ({
//               ...option,
//               checked: idx2 === optionIndex ? !option.checked : option.checked,
//             }));
//             return { ...question, options: updatedOptions };
//           }
//           return question;
//         });
//         return { ...section, questions: updatedQuestions };
//       }
//       return section;
//     });
//     setSections(updatedSections);
//     setQuestionErrors({});
//   };

//   // Validate the entire survey when the user clicks "View Results"
//   const validateAllSections = () => {
//     let isValid = true;
//     const errors = {};
//     const invalidSectionsList = [];

//     // Loop through each section and question to check if all are answered
//     sections.forEach((section, sectionIndex) => {
//       let sectionHasError = false;
//       section.questions.forEach((question) => {
//         const isQuestionValid = question.options.some(
//           (option) => option.checked
//         );
//         if (!isQuestionValid) {
//           errors[question.id] = "Please select at least one option.";
//           sectionHasError = true;
//           isValid = false;
//         }
//       });
//       if (sectionHasError) {
//         invalidSectionsList.push(section.title);
//       }
//     });

//     setQuestionErrors(errors);
//     setInvalidSections(invalidSectionsList);

//     // Scroll to the top if there's an error
//     if (!isValid) {
//       window.scrollTo(0, 0);
//     }

//     return isValid;
//   };

//   const handleNextSection = () => {
//     setCurrentSectionIndex(currentSectionIndex + 1); // Move to the next section
//     window.scrollTo(0, 0);
//     setQuestionErrors({});
//     setInvalidSections([]); // Reset the section error when moving to the next section
//   };

//   const handleViewResults = () => {
//     const isSurveyValid = validateAllSections(); // Validate the entire survey
//     window.scrollTo(0, 0);
//     if (isSurveyValid) {
//       storeData(); // Store data before viewing results
//       const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores(); // Get the calculated scores
//       setShowResults(true); // Show the results page
//     }
//   };

//   const storeData = () => {
//     const selectedOptionsData = {};
//     sections.forEach((section) => {
//       section.questions.forEach((question) => {
//         question.options.forEach((option, index) => {
//           const optionType =
//             option.type === "OP" ? "OP" : option.type === "FS" ? "FS" : "";
//           const variableName = `Q${question.id}_${optionType}`;
//           selectedOptionsData[variableName] = option.checked
//             ? option.weight || 0
//             : 0;
//         });
//       });
//     });

//     // console.log("Selected Options:", selectedOptionsData);
//     return selectedOptionsData;
//   };

//   const intropageData = () => {
//     return formData;
//   };

//   // Updated calculateScores function
//   const calculateScores = () => {
//     let totalWeight = 0;
//     let selectedScoreOP = 0;
//     let selectedScoreFS = 0;

//     sections[currentSectionIndex].questions.forEach((question) => {
//       question.options.forEach((option) => {
//         if (option.checked) {
//           totalWeight += option.weight;
//           if (option.type === "OP") {
//             selectedScoreOP += option.weight || 0;
//           } else if (option.type === "FS") {
//             selectedScoreFS += option.weight || 0;
//           }
//         }
//       });
//     });

//     return { totalWeight, selectedScoreOP, selectedScoreFS };
//   };

//   const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores();

//   const handleNavigateToSurvey = (index) => {
//     setShowResults(false);
//     setCurrentSectionIndex(index);
//   };

//   if (showResults) {
//     return (
//       <ResultsPage
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={setCurrentSectionIndex}
//         onNavigateToSurvey={handleNavigateToSurvey}
//         storeData={storeData}
//         formData={intropageData}
//       />
//     );
//   }

//   const hasError = Object.keys(questionErrors).length > 0;

//   return (
//     <div className="">
//       <div className="containerheader">
//         <p>Self Assessment</p>
//         <h2>Sales Operational Model</h2>
//       </div>

//       <Navbar
//         sections={sections}
//         currentSectionIndex={currentSectionIndex}
//         setCurrentSectionIndex={handleNavigateToSurvey}
//         invalidSections={invalidSections} // Pass invalid sections to Navbar
//       />

//       <div className="section">
//         <div className="questioninstruction">
//           Check the boxes that better match your market. If both options match
//           your reality in a relevant way (e.g., different channels or regions),
//           you can check both.
//         </div>
//         <div className="custom-hr"></div>
//         <h2 className="title">{sections[currentSectionIndex].title}</h2>
//         <div className="custom-hr"></div>

//         {hasError && (
//           <p className="error">
//             {`Please select at least one option in the following sections: ${invalidSections.join(
//               ", "
//             )}`}
//           </p>
//         )}

//         {sections[currentSectionIndex].questions.map((question) => (
//           <div
//             key={question.id}
//             className={`question ${
//               questionErrors[question.id] ? "option-error" : ""
//             }`}
//           >
//             <div className="question-text">{parse(question.text)}</div>
//             {question.options.map((option, index) => (
//               <label
//                 key={index}
//                 className={`option ${option.checked ? "selected" : ""}`}
//               >
//                 <input
//                   type="checkbox"
//                   checked={option.checked}
//                   onChange={() =>
//                     handleOptionChange(currentSectionIndex, question.id, index)
//                   }
//                 />
//                 {parse(option.text)}
//               </label>
//             ))}
//           </div>
//         ))}

//         {/* {currentSectionIndex === sections.length - 1 ? (
//           <Button label="View Results" onClick={handleViewResults}></Button>
//         ) : (
//           <Button label="Next Section" onClick={handleNextSection}></Button>
//         )} */}

// {/* <div className="button-container">
//         {currentSectionIndex === sections.length - 1 ? (
//           <Button label="View Results" onClick={handleViewResults} />
//         ) : (
//           <Button label="Next Section" onClick={handleNextSection} />
//         )}
//         <div className="logo-button-container">
//         <img src={logo} alt="Logo"/>
//         </div>
//       </div> */}
//       <div className="button-container">
//   <div className="button-center">
//     {currentSectionIndex === sections.length - 1 ? (
//       <Button label="View Results" onClick={handleViewResults} />
//     ) : (
//       <Button label="Next Section" onClick={handleNextSection} />
//     )}
//   </div>
//   {/* <div className="logo-button-container">
//     <img src={logo} alt="Logo" />
//   </div> */}
// </div>

//       </div>
//     </div>
//   );
// }

// export default Survey;

import React, { useState, useEffect } from "react";
import sectionsData from "./section.json";
import "./Survey.css";
import parse from "html-react-parser";
import Button from "./Button";
import ResultsPage from "./ResultsPage";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import logo from './logo.png'

function Survey() {
  const [sections, setSections] = useState(sectionsData.sections);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [questionErrors, setQuestionErrors] = useState({});
  const [invalidSections, setInvalidSections] = useState([]); // List of sections with errors
  const [showResults, setShowResults] = useState(false);

  const location = useLocation();
  const [formData, setFormData] = useState({
    mu: "",
    cu: "",
    assessedBy: "",
    email: "",
  });

  useEffect(() => {
    if (location.state) {
      setFormData(location.state);
    }
  }, [location.state]);

  // Function to randomize options for a given section's questions
  const randomizeOptions = (questions) => {
    return questions.map((question) => {
      const shuffledOptions = [...question.options].sort(
        () => Math.random() - 0.5
      );
      return { ...question, options: shuffledOptions };
    });
  };

  // Initialize sections with randomized options when the component loads
  useEffect(() => {
    const randomizedSections = sections.map((section) => {
      const randomizedQuestions = randomizeOptions(section.questions);
      return { ...section, questions: randomizedQuestions };
    });
    setSections(randomizedSections);
  }, []); // This runs once when the component is first loaded

  const handleOptionChange = (sectionIndex, questionId, optionIndex) => {
    const updatedSections = sections.map((section, idx) => {
      if (idx === sectionIndex) {
        const updatedQuestions = section.questions.map((question) => {
          if (question.id === questionId) {
            const updatedOptions = question.options.map((option, idx2) => ({
              ...option,
              checked: idx2 === optionIndex ? !option.checked : option.checked,
            }));
            return { ...question, options: updatedOptions };
          }
          return question;
        });
        return { ...section, questions: updatedQuestions };
      }
      return section;
    });
    setSections(updatedSections);
    setQuestionErrors({});
  };

  // Validate the entire survey when the user clicks "View Results"
  // const validateAllSections = () => {
  //   let isValid = true;
  //   const errors = {};
  //   const invalidSectionsList = [];

  //   // Loop through each section and question to check if all are answered
  //   sections.forEach((section, sectionIndex) => {
  //     let sectionHasError = false;
  //     section.questions.forEach((question) => {
  //       const isQuestionValid = question.options.some(
  //         (option) => option.checked
  //       );
  //       if (!isQuestionValid) {
  //         errors[question.id] = "Please select at least one option.";
  //         sectionHasError = true;
  //         isValid = false;
  //       }
  //     });
  //     if (sectionHasError) {
  //       invalidSectionsList.push(section.title);
  //     }
  //   });

  //   setQuestionErrors(errors);
  //   setInvalidSections(invalidSectionsList);

  //   // Scroll to the top if there's an error
  //   if (!isValid) {
  //     window.scrollTo(0, 0);
  //   }

  //   return isValid;
  // };

  const validateOptions = () => {
    const currentQuestions = sections[currentSectionIndex].questions;
    let isValid = true;
    const errors = {};
  
    currentQuestions.forEach((question) => {
      const isQuestionValid = question.options.some((option) => option.checked);
      if (!isQuestionValid) {
        errors[question.id] = "Please select at least one option.";
        isValid = false;
      }
    });
  
    setQuestionErrors(errors);
    return isValid;
  };

  const handleNextSection = () => {
    // setCurrentSectionIndex(currentSectionIndex + 1); // Move to the next section
    // window.scrollTo(0, 0);
    // setQuestionErrors({});
    // setInvalidSections([]); // Reset the section error when moving to the next section

     const isSectionValid = validateOptions(); // Validate only the current section
  if (isSectionValid) {
    setCurrentSectionIndex(currentSectionIndex + 1); // Move to the next section if valid
    window.scrollTo(0, 0);
    setQuestionErrors({});
    setInvalidSections([]); // Reset section errors when moving to the next section
  } else {
    window.scrollTo(0, 0); // Scroll to the top if there's an error
  }
  };

  const handleViewResults = () => {
    // const isSurveyValid = validateAllSections(); // Validate the entire survey
    // window.scrollTo(0, 0);
    // if (isSurveyValid) {
    //   storeData(); // Store data before viewing results
    //   const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores(); // Get the calculated scores
    //   setShowResults(true); // Show the results page
    // }

    const isCurrentSectionValid = validateOptions(); // Validate the current section before viewing results

  if (isCurrentSectionValid) {
    window.scrollTo(0, 0); // Scroll to the top if there's no error
    storeData(); // Store data before viewing results
    const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores(); // Get the calculated scores
    setShowResults(true); // Show the results page
  } else {
    window.scrollTo(0, 0); // Scroll to the top if there's an error
  }
  };

  const storeData = () => {
    const selectedOptionsData = {};
    sections.forEach((section) => {
      section.questions.forEach((question) => {
        question.options.forEach((option, index) => {
          const optionType =
            option.type === "OP" ? "OP" : option.type === "FS" ? "FS" : "";
          const variableName = `Q${question.id}_${optionType}`;
          selectedOptionsData[variableName] = option.checked
            ? option.weight || 0
            : 0;
        });
      });
    });

    // console.log("Selected Options:", selectedOptionsData);
    return selectedOptionsData;
  };

  const intropageData = () => {
    return formData;
  };

  // Updated calculateScores function
  const calculateScores = () => {
    let totalWeight = 0;
    let selectedScoreOP = 0;
    let selectedScoreFS = 0;

    sections[currentSectionIndex].questions.forEach((question) => {
      question.options.forEach((option) => {
        if (option.checked) {
          totalWeight += option.weight;
          if (option.type === "OP") {
            selectedScoreOP += option.weight || 0;
          } else if (option.type === "FS") {
            selectedScoreFS += option.weight || 0;
          }
        }
      });
    });

    return { totalWeight, selectedScoreOP, selectedScoreFS };
  };

  const { totalWeight, selectedScoreOP, selectedScoreFS } = calculateScores();

  const handleNavigateToSurvey = (index) => {
    setShowResults(false);
    setCurrentSectionIndex(index);
  };

  if (showResults) {
    return (
      <ResultsPage
        sections={sections}
        currentSectionIndex={currentSectionIndex}
        setCurrentSectionIndex={setCurrentSectionIndex}
        onNavigateToSurvey={handleNavigateToSurvey}
        storeData={storeData}
        formData={intropageData}
      />
    );
  }

  const hasError = Object.keys(questionErrors).length > 0;

  return (
    <div className="">
      <div className="containerheader">
        <p>Self Assessment</p>
        <h2>Sales Operational Model</h2>
      </div>

      <Navbar
        sections={sections}
        currentSectionIndex={currentSectionIndex}
        setCurrentSectionIndex={handleNavigateToSurvey}
        invalidSections={invalidSections} // Pass invalid sections to Navbar
      />

      <div className="section">
        <div className="questioninstruction">
          Check the boxes that better match your market. If both options match
          your reality in a relevant way (e.g., different channels or regions),
          you can check both.
        </div>
        <div className="custom-hr"></div>
        <h2 className="title">{sections[currentSectionIndex].title}</h2>
        <div className="custom-hr"></div>

        {hasError && (
          <p className="error">
            {`Please select at least one option for each question.`}
          </p>
        )}

        {sections[currentSectionIndex].questions.map((question) => (
          <div
            key={question.id}
            className={`question ${
              questionErrors[question.id] ? "option-error" : ""
            }`}
          >
            <div className="question-text">{parse(question.text)}</div>
            {question.options.map((option, index) => (
              <label
                key={index}
                className={`option ${option.checked ? "selected" : ""}`}
              >
                <input
                  type="checkbox"
                  checked={option.checked}
                  onChange={() =>
                    handleOptionChange(currentSectionIndex, question.id, index)
                  }
                />
                {parse(option.text)}
              </label>
            ))}
          </div>
        ))}

        {/* {currentSectionIndex === sections.length - 1 ? (
          <Button label="View Results" onClick={handleViewResults}></Button>
        ) : (
          <Button label="Next Section" onClick={handleNextSection}></Button>
        )} */}

{/* <div className="button-container">
        {currentSectionIndex === sections.length - 1 ? (
          <Button label="View Results" onClick={handleViewResults} />
        ) : (
          <Button label="Next Section" onClick={handleNextSection} />
        )}
        <div className="logo-button-container">
        <img src={logo} alt="Logo"/>
        </div>
      </div> */}
      <div className="button-container">
  <div className="button-center">
    {currentSectionIndex === sections.length - 1 ? (
      <Button label="View Results" onClick={handleViewResults} />
    ) : (
      <Button label="Next Section" onClick={handleNextSection} />
    )}
  </div>
  {/* <div className="logo-button-container">
    <img src={logo} alt="Logo" />
  </div> */}
</div>

      </div>
    </div>
  );
}

export default Survey;