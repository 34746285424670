import React from 'react';
import './Header.css'; // Optional: for styling
import header from './header.jpg'

function Header() {
  return (
    <header className="header">
      <img
        src={header} // Replace with your image path
        alt="Header Image"
        className="header-image"
      />
    </header>
  );
}

export default Header;
