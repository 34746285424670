import logo from './logo.svg';
import './App.css';
import Survey from './Survey';
import InfoPage from './Intropage';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Data from './Data';
import ThankYouPage from './Components/ThankYouPage'; // Import the new Thank You page
import Dashboard from './Dashboard';
import Header from './Components/Header';
import './Survey.css'
import Footer from './Components/Footer'


function App() {
  return (
    // <div className="App">
    //   < InfoPage />
    //   {/* <Survey /> */}
    // </div>
    <Router>
      <div className="App survey-container">
      <Header />
        <Routes>
          <Route path="/" element={<InfoPage />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/data" element={<Data />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
