// import React, { useEffect, useState } from 'react';
// import PieChartComponent from './Components/PieChartComponent';
// import './Dashboard.css'
// import config from './config'

// const Dashboard = () => {
//   const [summaryData, setSummaryData] = useState({});
//   const [loading, setLoading] = useState(true);
  
//   // States for filters
//   const [cu, setCU] = useState('');
//   const [mu, setMU] = useState('');
//   const [assessedBy, setAssessedBy] = useState('');

//   // Fetch data from the backend
//   const fetchData = async (filters = {}) => {
//     try {
//       const queryParams = new URLSearchParams(filters).toString();
//       const response = await fetch(`${config.API_BASE_URL}/summarize-results?${queryParams}`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       setSummaryData(data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();  // Initial fetch without filters
//   }, []);

//   const handleApplyFilters = () => {
//     // Apply filters and fetch filtered data
//     const filters = { cu, mu, assessed_by: assessedBy };
//     fetchData(filters);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   const CustomTooltip = ({ active, payload, totalWeight }) => {
//     if (active && payload && payload.length) {
//       const fiveStarsData = payload.find((p) => p.name === "FIVE STARS");
//       const opexData = payload.find((p) => p.name === "OPEX");

//       const fiveStarsValue = fiveStarsData ? fiveStarsData.value : 0;
//       const opexValue = opexData ? opexData.value : 0;
//       const totalValue = fiveStarsValue + opexValue;

//       const { name, value } = payload[0];
//       const percentage = totalValue > 0 ? ((value / totalWeight) * 100).toFixed(2) : 0;

//       return (
//         <div className="custom-tooltip">
//           <p style={{ color: name === "FIVE STARS" ? "#0088FE" : "#FF0000" }}>
//             {`${name}: ${percentage}%`}
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   const pieChartData = [
//     {
//       name: 'Data availability',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section1_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section1_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Retail Landscape',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section2_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section2_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Scale and Size of Prize',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section3_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section3_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Internal Capabilities',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section4_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section4_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Total Score',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.totalScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.totalScoreOP || 0 },
//       ],
//     },
//   ];

//   return (

//     <div className="section">
// {/* Top navigation bar for Filters */}
// <div className="top-navbar">
//   <h4>Filters</h4>
//   <div className="filter-group">
//     <div>
//       <label>CU:</label>
//       <input type="text" value={cu} onChange={(e) => setCU(e.target.value)} />
//     </div>
//     <div>
//       <label>MU:</label>
//       <input type="text" value={mu} onChange={(e) => setMU(e.target.value)} />
//     </div>
//     <div>
//       <label>Assessed By:</label>
//       <input type="text" value={assessedBy} onChange={(e) => setAssessedBy(e.target.value)} />
//     </div>
//     <button onClick={handleApplyFilters}>Apply</button>
//   </div>
// </div>

//       {/* Pie Charts Section */}
//       <div style={{ width: '80%', padding: '10px' }}>
//         <h1>Dashboard</h1>
//         <div className="charts-row">
//           {pieChartData.map((section, index) => {
//             const totalWeight = section.data.reduce((acc, curr) => acc + curr.value, 0);

//             return (
//               <div key={index} style={{ margin: '10px' }}>
//                 <div>
//                 <h4>{section.name}</h4>
//                 <PieChartComponent
//                   data={section.data}
//                   width={300}
//                   height={300}
//                   outerRadius={100}
//                   innerRadius={40}
//                   customTooltip={<CustomTooltip totalWeight={totalWeight} />}
//                 />
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;


// import React, { useEffect, useState } from 'react';
// import PieChartComponent from './Components/PieChartComponent';
// import './Dashboard.css';
// import config from './config';
// import Button from "./Button";
// import logo from "./logo.png"

// const Dashboard = () => {
//   const [summaryData, setSummaryData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [totalRespondents, setTotalRespondents] = useState(0); // State for total respondents

//   // States for filters
//   const [cu, setCU] = useState('');
//   const [mu, setMU] = useState('');
//   const [assessedBy, setAssessedBy] = useState('');

//   // Fetch data from the backend
//   // const fetchData = async (filters = {}) => {
//   //   try {
//   //     const queryParams = new URLSearchParams(filters).toString();
//   //     const response = await fetch(`${config.API_BASE_URL}/summarize-results?${queryParams}`);
//   //     if (!response.ok) {
//   //       throw new Error('Network response was not ok');
//   //     }
//   //     const data = await response.json();
//   //     setSummaryData(data);
//   //     setLoading(false);
//   //   } catch (error) {
//   //     console.error('Error fetching data:', error);
//   //     setLoading(false);
//   //   }
//   // };

//   const fetchData = async (filters = {}) => {
//     try {
//         const queryParams = new URLSearchParams(filters).toString();
//         const response = await fetch(`${config.API_BASE_URL}/summarize-results?${queryParams}`);
//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         setSummaryData(data);
//         setTotalRespondents(data.totalRespondents || 0); // Set total respondents from the fetched data
//         setLoading(false);
//     } catch (error) {
//         console.error('Error fetching data:', error);
//         setLoading(false);
//     }
// };


//   useEffect(() => {
//     fetchData();  // Initial fetch without filters
//   }, []);

//   const handleApplyFilters = () => {
//     // Apply filters and fetch filtered data
//     const filters = { cu, mu, assessed_by: assessedBy };
//     fetchData(filters);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   const CustomTooltip = ({ active, payload, totalWeight }) => {
//     if (active && payload && payload.length) {
//       const fiveStarsData = payload.find((p) => p.name === "FIVE STARS");
//       const opexData = payload.find((p) => p.name === "OPEX");

//       const fiveStarsValue = fiveStarsData ? fiveStarsData.value : 0;
//       const opexValue = opexData ? opexData.value : 0;
//       const totalValue = fiveStarsValue + opexValue;

//       const { name, value } = payload[0];
//       const percentage = totalValue > 0 ? ((value / totalWeight) * 100).toFixed(2) : 0;

//       return (
//         <div className="custom-tooltip">
//           <p style={{ color: name === "FIVE STARS" ? "#0088FE" : "#FF0000" }}>
//             {`${name}: ${percentage}%`}
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   const pieChartData = [
//     {
//       name: 'Data availability',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section1_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section1_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Retail Landscape',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section2_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section2_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Scale and Size of Prize',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section3_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section3_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Internal Capabilities',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section4_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section4_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Total Score',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.totalScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.totalScoreOP || 0 },
//       ],
//     },
//   ];

//   return (
//     <div className="section">
// <div className="containerheader">
//      <h1>Dashboard</h1>
//      </div>
//       {/* Top navigation bar for Filters */}
//       <div className="top-navbar">
//         <h4>Filters</h4>
//         <div className="filter-group">
//           <div>
//             <label>CU:</label>
//             <input type="text" value={cu} onChange={(e) => setCU(e.target.value)} />
//           </div>
//           <div>
//             <label>MU:</label>
//             <input type="text" value={mu} onChange={(e) => setMU(e.target.value)} />
//           </div>
//           <div>
//             <label>Assessed By:</label>
//             <input type="text" value={assessedBy} onChange={(e) => setAssessedBy(e.target.value)} />
//           </div>
//           <Button label="Apply" onClick={handleApplyFilters}></Button>
//         </div>
//       </div>
// {/* Total Respondents */}
// <div className="respondent-count">
//         <h2>Total Respondents: {totalRespondents}</h2>
//       </div>
//       {/* Pie Charts Section */}
//       <div className="charts-section">
//         <div className="charts-row">
//           {pieChartData.map((section, index) => {
//             const totalWeight = section.data.reduce((acc, curr) => acc + curr.value, 0);

//             return (
//               <div key={index} className="section-chart">
//                 <div>
//                   <h4>{section.name}</h4>
//                   <PieChartComponent
//                     data={section.data}
//                     width={300}
//                     height={300}
//                     outerRadius={100}
//                     innerRadius={40}
//                     customTooltip={<CustomTooltip totalWeight={totalWeight} />}
//                   />
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//         <div className="logo-button-container">
//     <img src={logo} alt="Logo" />
//   </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

// import React, { useEffect, useState } from 'react';
// import Select from 'react-select';
// import PieChartComponent from './Components/PieChartComponent';
// import './Dashboard.css';
// import config from './config';
// import Button from "./Button";
// import logo from "./logo.png";

// const Dashboard = () => {
//   const [summaryData, setSummaryData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [totalRespondents, setTotalRespondents] = useState(0); // State for total respondents

//   // States for filters
//   const [cu, setCU] = useState('');
//   const [mu, setMU] = useState('');
//   const [assessedBy, setAssessedBy] = useState('');
//   const [email, setEmail] = useState('');

//   // States for options in searchable dropdowns
//   const [cuOptions, setCuOptions] = useState([]);
//   const [muOptions, setMuOptions] = useState([]);
//   const [assessedByOptions, setAssessedByOptions] = useState([]);
//   const [emailOptions, setEmailOptions] = useState([]);

//   // Fetch data from the backend
//   const fetchData = async (filters = {}) => {
//     try {
//         const queryParams = new URLSearchParams(filters).toString();
//         const response = await fetch(`${config.API_BASE_URL}/summarize-results?${queryParams}`);
//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         setSummaryData(data);
//         setTotalRespondents(data.totalRespondents || 0); // Set total respondents from the fetched data
//         setCuOptions(data.cuOptions || []);
//         setMuOptions(data.muOptions || []);
//         setAssessedByOptions(data.assessedByOptions || []);
//         setEmailOptions(data.emailOptions || []);
//         setLoading(false);
//     } catch (error) {
//         console.error('Error fetching data:', error);
//         setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();  // Initial fetch without filters
//   }, []);

//   const handleApplyFilters = () => {
//     // Apply filters and fetch filtered data
//     const filters = { cu, mu, assessed_by: assessedBy, email };
//     fetchData(filters);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   const CustomTooltip = ({ active, payload, totalWeight }) => {
//     if (active && payload && payload.length) {
//       const fiveStarsData = payload.find((p) => p.name === "FIVE STARS");
//       const opexData = payload.find((p) => p.name === "OPEX");

//       const fiveStarsValue = fiveStarsData ? fiveStarsData.value : 0;
//       const opexValue = opexData ? opexData.value : 0;
//       const totalValue = fiveStarsValue + opexValue;

//       const { name, value } = payload[0];
//       const percentage = totalValue > 0 ? ((value / totalWeight) * 100).toFixed(2) : 0;

//       return (
//         <div className="custom-tooltip">
//           <p style={{ color: name === "FIVE STARS" ? "#0088FE" : "#FF0000" }}>
//             {`${name}: ${percentage}%`}
//           </p>
//         </div>
//       );
//     }
//     return null;
//   };

//   const pieChartData = [
//     {
//       name: 'Data availability',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section1_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section1_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Retail Landscape',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section2_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section2_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Scale and Size of Prize',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section3_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section3_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Internal Capabilities',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.section4_selectedScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.section4_selectedScoreOP || 0 },
//       ],
//     },
//     {
//       name: 'Total Score',
//       data: [
//         { name: 'FIVE STARS', value: summaryData.totalScoreFS || 0 },
//         { name: 'OPEX', value: summaryData.totalScoreOP || 0 },
//       ],
//     },
//   ];

//   return (
//     <div className="section">
//       <div className="containerheader">
//         <h1>Dashboard</h1>
//       </div>
//       {/* Top navigation bar for Filters */}
//       <div className="top-navbar">
//         <h4>Filters</h4>
//         <div className="filter-group">
//           <div>
//             <label>CU:</label>
//             <Select 
//               options={cuOptions.map(option => ({ value: option, label: option }))}
//               value={cu ? { value: cu, label: cu } : null}
//               onChange={(selectedOption) => setCU(selectedOption?.value || '')}
//               isClearable
//               placeholder="Select CU"
//             />
//           </div>
//           <div>
//             <label>MU:</label>
//             <Select 
//               options={muOptions.map(option => ({ value: option, label: option }))}
//               value={mu ? { value: mu, label: mu } : null}
//               onChange={(selectedOption) => setMU(selectedOption?.value || '')}
//               isClearable
//               placeholder="Select MU"
//             />
//           </div>
//           <div>
//             <label>Assessed By:</label>
//             <Select 
//               options={assessedByOptions.map(option => ({ value: option, label: option }))}
//               value={assessedBy ? { value: assessedBy, label: assessedBy } : null}
//               onChange={(selectedOption) => setAssessedBy(selectedOption?.value || '')}
//               isClearable
//               placeholder="Select Assessed By"
//             />
//           </div>
//           <div>
//             <label>Email:</label>
//             <Select 
//               options={emailOptions.map(option => ({ value: option, label: option }))}
//               value={email ? { value: email, label: email } : null}
//               onChange={(selectedOption) => setEmail(selectedOption?.value || '')}
//               isClearable
//               placeholder="Select Email"
//             />
//           </div>
//           <Button label="Apply" onClick={handleApplyFilters}></Button>
//         </div>
//       </div>

//       {/* Total Respondents */}
//       <div className="respondent-count">
//         <h2>Total Respondents: {totalRespondents}</h2>
//       </div>

//       {/* Pie Charts Section */}
//       <div className="charts-section">
//         <div className="charts-row">
//           {pieChartData.map((section, index) => {
//             const totalWeight = section.data.reduce((acc, curr) => acc + curr.value, 0);

//             return (
//               <div key={index} className="section-chart">
//                 <div>
//                   <h4>{section.name}</h4>
//                   <PieChartComponent
//                     data={section.data}
//                     width={300}
//                     height={300}
//                     outerRadius={100}
//                     innerRadius={40}
//                     customTooltip={<CustomTooltip totalWeight={totalWeight} />}
//                   />
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//         <div className="logo-button-container">
//           <img src={logo} alt="Logo" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;


import React, { useEffect, useState } from 'react';
import PieChartComponent from './Components/PieChartComponent';
import './Dashboard.css';
import config from './config';
import Button from "./Button";
import logo from "./logo.png";
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [summaryData, setSummaryData] = useState({});
  const [loading, setLoading] = useState(true);
  const [totalRespondents, setTotalRespondents] = useState(0); // State for total respondents
  const navigate = useNavigate();

  // States for filters
  const [cu, setCU] = useState('');
  const [mu, setMU] = useState('');
  const [assessedBy, setAssessedBy] = useState('');
  const [email, setEmail] = useState('');

  // States for options in dropdowns
  const [cuOptions, setCuOptions] = useState([]);
  const [muOptions, setMuOptions] = useState([]);
  const [assessedByOptions, setAssessedByOptions] = useState([]);
  const [emailOptions, setEmailOptions] = useState([]);

  // Fetch data from the backend
  const fetchData = async (filters = {}) => {
    try {
        const queryParams = new URLSearchParams(filters).toString();
        const response = await fetch(`${config.API_BASE_URL}/summarize-results?${queryParams}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSummaryData(data);
        setTotalRespondents(data.totalRespondents || 0); // Set total respondents from the fetched data
        setCuOptions(data.cuOptions || []);
        setMuOptions(data.muOptions || []);
        setAssessedByOptions(data.assessedByOptions || []);
        setEmailOptions(data.emailOptions || []);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();  // Initial fetch without filters
  }, []);

  const handleApplyFilters = () => {
    // Apply filters and fetch filtered data
    const filters = { cu, mu, assessed_by: assessedBy, email };
    fetchData(filters);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const CustomTooltip = ({ active, payload, totalWeight }) => {
    if (active && payload && payload.length) {
      const fiveStarsData = payload.find((p) => p.name === "FIVE STARS");
      const opexData = payload.find((p) => p.name === "OPEX");

      const fiveStarsValue = fiveStarsData ? fiveStarsData.value : 0;
      const opexValue = opexData ? opexData.value : 0;
      const totalValue = fiveStarsValue + opexValue;

      const { name, value } = payload[0];
      const percentage = totalValue > 0 ? ((value / totalWeight) * 100).toFixed(2) : 0;

      return (
        <div className="custom-tooltip">
          <p style={{ color: name === "FIVE STARS" ? "#0088FE" : "#FF0000" }}>
            {`${name}: ${percentage}%`}
          </p>
        </div>
      );
    }
    return null;
  };

  const pieChartData = [
    {
      name: 'Data availability',
      data: [
        { name: 'FIVE STARS', value: summaryData.section1_selectedScoreFS || 0 },
        { name: 'OPEX', value: summaryData.section1_selectedScoreOP || 0 },
      ],
    },
    {
      name: 'Retail Landscape',
      data: [
        { name: 'FIVE STARS', value: summaryData.section2_selectedScoreFS || 0 },
        { name: 'OPEX', value: summaryData.section2_selectedScoreOP || 0 },
      ],
    },
    {
      name: 'Scale and Size of Prize',
      data: [
        { name: 'FIVE STARS', value: summaryData.section3_selectedScoreFS || 0 },
        { name: 'OPEX', value: summaryData.section3_selectedScoreOP || 0 },
      ],
    },
    {
      name: 'Internal Capabilities',
      data: [
        { name: 'FIVE STARS', value: summaryData.section4_selectedScoreFS || 0 },
        { name: 'OPEX', value: summaryData.section4_selectedScoreOP || 0 },
      ],
    },
    {
      name: 'Total Score',
      data: [
        { name: 'FIVE STARS', value: summaryData.totalScoreFS || 0 },
        { name: 'OPEX', value: summaryData.totalScoreOP || 0 },
      ],
    },
  ];

  const handleData = () =>{
    navigate('/data');
  }

  return (
    <div className="section">
      <div className="containerheader">
        <h1>Dashboard</h1>
      </div>
      {/* Top navigation bar for Filters */}
      {/* <div className="top-navbar">
        <h4>Filters</h4>
        <div className="filter-group">
          <div>
            <label>CU:</label>
            <select value={cu} onChange={(e) => setCU(e.target.value)}>
              <option value="">Select CU</option>
              {cuOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div>
            <label>MU:</label>
            <select value={mu} onChange={(e) => setMU(e.target.value)}>
              <option value="">Select MU</option>
              {muOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Assessed By:</label>
            <select value={assessedBy} onChange={(e) => setAssessedBy(e.target.value)}>
              <option value="">Select Assessed By</option>
              {assessedByOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Email:</label>
            <select value={email} onChange={(e) => setEmail(e.target.value)}>
              <option value="">Select Email</option>
              {emailOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <Button label="Apply" onClick={handleApplyFilters}></Button>
        </div>
      </div> */}

<div className="top-navbar">
  <h4>Filters</h4>
  <div className="filter-group">
    <div>
      <label>CU:</label>
      <input
        list="cuOptions"
        value={cu}
        onChange={(e) => setCU(e.target.value)}
        placeholder="Type CU here..."
      />
      <datalist id="cuOptions">
        {cuOptions.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </div>
    <div>
      <label>MU:</label>
      <input
        list="muOptions"
        value={mu}
        onChange={(e) => setMU(e.target.value)}
        placeholder="Type MU here..."
      />
      <datalist id="muOptions">
        {muOptions.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </div>
    <div>
      <label>Assessed By:</label>
      <input
        list="assessedByOptions"
        value={assessedBy}
        onChange={(e) => setAssessedBy(e.target.value)}
        placeholder="Type Assessed By here..."
      />
      <datalist id="assessedByOptions">
        {assessedByOptions.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </div>
    <div>
      <label>Email:</label>
      <input
        list="emailOptions"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Type Email here..."
      />
      <datalist id="emailOptions">
        {emailOptions.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </div>
    <Button label="Apply" onClick={handleApplyFilters}></Button>
    <Button label="Data" onClick={handleData}></Button>
  </div>
</div>


      {/* Total Respondents */}
      <div className="respondent-count">
        <h2>Total Respondents: {totalRespondents}</h2>
      </div>

      {/* Pie Charts Section */}
      <div className="charts-section">
        <div className="charts-row">
          {pieChartData.map((section, index) => {
            const totalWeight = section.data.reduce((acc, curr) => acc + curr.value, 0);

            return (
              <div key={index} className="section-chart">
                <div>
                  <h4>{section.name}</h4>
                  <PieChartComponent
                    data={section.data}
                    width={300}
                    height={300}
                    outerRadius={100}
                    innerRadius={40}
                    customTooltip={<CustomTooltip totalWeight={totalWeight} />}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="logo-button-container">
          <img src={logo} alt="Logo" />
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
