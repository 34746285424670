import React, { useState, useEffect } from 'react';
import './Survey.css';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import logo from './logo.png'
import Header from './Components/Header';
import './ResultsPage';


const InfoPage = () => {
  const [mu, setMu] = useState('');
  const [cu, setCu] = useState('');
  const [assessedBy, setAssessedBy] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [starttime, setstarttime] = useState(''); // New state for load time


  const navigate = useNavigate();

  useEffect(() => {
    const now = new Date();
    setstarttime(now.toLocaleString()); // Set the load time when the component mounts
  }, []);

  const validateFields = () => {
    const newErrors = {
      mu: mu ? '' : 'Please enter MU',
      cu: cu ? '' : 'Please enter CU',
      assessedBy: assessedBy ? '' : 'Please enter Assessed by',
      email: email ? (/\S+@\S+\.\S+/.test(email) ? '' : 'Please enter a valid email') : 'Please enter Email',
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

   // Create an object to hold the MU, CU, AssessedBy, and Email values
   const formData = {
    mu,
    cu,
    assessedBy,
    email,
    starttime, // Include loadTime in the form data
  };

  const handleNextClick = () => {
    console.log("formData",formData,)
    if (!validateFields()) {
      return;
    }
    window.scrollTo(0, 0);
    // console.log("formData",formData)
    navigate('/survey', {
      state: formData,
    });
  };

  const handleInputChange = (setter, field) => (e) => {
    setter(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
  };

  const hasError = Object.values(errors).some(error => error);

  return (
    <div className="">
      <div className="containerheader">
        <h2>Welcome to the Beiersdorf SALES OPERATIONAL MODEL assessment</h2>
      </div>
      <div className="section">
        <h2>Please consider the following instructions to select the most adequate model for your market.</h2>
        <div className='introduction'>
          <p>1. Answer all questions based on current market conditions.</p>
          <p>2. Select the option that better reflects the reality of your market. In case both options could be relevant to you, select both.</p>
          <p>3. Validate the choice with your GM and submit your results to your sales VP.</p>
        </div>
        {hasError && <p className="error">Please fill out all the fields correctly.</p>}
        <div className="custom-hr"></div>
        <div className="input-fields">
        {errors.mu && <p className="error">{errors.mu}</p>} {/* Ensure error message appears below the input */}
          <div className="input-pair">
            <label htmlFor="mu">MU:</label>
            <input
              id="mu"
              type="text"
              value={mu}
              onChange={handleInputChange(setMu, 'mu')}
            />
            
          </div>
          {errors.cu && <p className="error">{errors.cu}</p>} {/* Ensure error message appears below the input */}
          <div className="input-pair">
            <label htmlFor="cu">CU:</label>
            <input
              id="cu"
              type="text"
              value={cu}
              onChange={handleInputChange(setCu, 'cu')}
            />
            
          </div>
          {errors.assessedBy && <p className="error">{errors.assessedBy}</p>} {/* Ensure error message appears below the input */}
          <div className="input-pair">
            <label htmlFor="assessedBy">Assessed by:</label>
            <input
              id="assessedBy"
              type="text"
              value={assessedBy}
              onChange={handleInputChange(setAssessedBy, 'assessedBy')}
            />
            
          </div>
          {errors.email && <p className="error">{errors.email}</p>} {/* Ensure error message appears below the input */}
          <div className="input-pair">
            <label htmlFor="email">Email:</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={handleInputChange(setEmail, 'email')}
            />
            
          </div>
        </div>
        
        <div className="button-container">
  <div className="button-center">
  <Button label="Next Section" onClick={handleNextClick}></Button>
  </div>
  {/* <div className="logo-button-container">
    <img src={logo} alt="Logo" />
  </div> */}
</div>

      </div>
    </div>
  );
};

export default InfoPage;
