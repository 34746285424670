import React from 'react';
import PropTypes from 'prop-types';
 
const Button = ({ label, onClick, className, disabled }) => {
  return (
<button 
      onClick={onClick} 
      className={`btn ${className}`} 
      disabled={disabled}
>
      {label}
</button>
  );
};
 
// Define prop types for the Button component
Button.propTypes = {
  label: PropTypes.string.isRequired,   // The button label
  onClick: PropTypes.func,               // Function to call on button click
  className: PropTypes.string,            // Additional CSS classes
  disabled: PropTypes.bool,               // Flag to disable the button
};
 
// Default props for the Button component
Button.defaultProps = {
  className: '',                         // No additional classes by default
  disabled: false                        // Button is enabled by default
};
 
export default Button;