// // import React from 'react';

// // const Navbar = ({ sections, currentSectionIndex, setCurrentSectionIndex }) => {
// //     return (
// //         <div className="navbar">
// //             {sections.map((section, index) => (
// //                 <button
// //                     key={index}
// //                     disabled={index > currentSectionIndex}
// //                     onClick={() => setCurrentSectionIndex(index)}
// //                     className={currentSectionIndex === index ? 'active' : ''}
// //                 >
// //                     {section.title}
// //                 </button>
// //             ))}
// //         </div>
// //     );
// // };

// // export default Navbar;

// import React from 'react';

// const Navbar = ({ sections, currentSectionIndex, setCurrentSectionIndex, invalidSections }) => {
//     return (
//         <div className="navbar">
//             {sections.map((section, index) => (
//                 <button
//                     key={index}
//                     disabled={index > currentSectionIndex}
//                     onClick={() => setCurrentSectionIndex(index)}
//                     className={`nav-button ${currentSectionIndex === index ? 'active' : ''} ${invalidSections.includes(section.title) ? 'error-section' : ''}`}
//                 >
//                     {section.title}
//                 </button>
//             ))}
//         </div>
//     );
// };

// export default Navbar;

import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ sections, currentSectionIndex, setCurrentSectionIndex, invalidSections = [] }) => {
    const navigate = useNavigate();
 
    const handleButtonClick = () => {
        navigate('/');
    };
 
    return (
        <div className="navbar">
            {/* <button onClick={handleButtonClick}>
            Intropage
        </button> */}
            {sections.map((section, index) => (
                <button
                    key={index}
                    disabled={index > currentSectionIndex}
                    onClick={() => setCurrentSectionIndex(index)}
                    className={`nav-button ${currentSectionIndex === index ? 'active' : ''} ${invalidSections.includes(section.title) ? 'error-section' : ''}`}
                >
                    {section.title}
                </button>
            ))}
        </div>
    );
};

export default Navbar;

// import React from 'react';

// const Navbar = ({ sections, currentSectionIndex, setCurrentSectionIndex, invalidSections = [] }) => {
//     return (
//         <div className="navbar">
//             {/* Add "Intro" button to navigate to InfoPage */}
//             <button
//                 onClick={() => setCurrentSectionIndex(-1)} // -1 will be used to identify the Intro page
//                 className={currentSectionIndex === -1 ? 'active' : ''}
//             >
//                 Intro
//             </button>

//             {/* Existing section buttons */}
//             {sections.map((section, index) => (
//                 <button
//                     key={index}
//                     disabled={index > currentSectionIndex}
//                     onClick={() => setCurrentSectionIndex(index)}
//                     className={`nav-button ${currentSectionIndex === index ? 'active' : ''} ${invalidSections.includes(section.title) ? 'error-section' : ''}`}
//                 >
//                     {section.title}
//                 </button>
//             ))}
//         </div>
//     );
// };

// export default Navbar;
