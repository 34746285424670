// ThankYouPage.js
import React from 'react';
import './ThankYouPage.css';
// import '../Survey.css';
import logo from '../logo.png'

const ThankYouPage = () => {
  return (
    
<div className='content'>
  <div className="wrapper-1">
    <div className="wrapper-2">
      <h1 id='h11'>Thank you !</h1>
      <p>Thank you for your assessment. The report was sent to the registered email.</p>
      <p>Please share it with your leadership team as soon as you receive it.</p>
      <br/>
      <p>For issues with the submission, please contact <a href="mailto:BDFSALESMODEL@bdfgrp.onmicrosoft.com">BDFSALESMODEL@bdfgrp.onmicrosoft.com</a>.
      </p>
      {/* <button className="go-home">
      go home
      </button> */}
    </div>
    {/* <div className="footer-like">
      <p>Email not received?
       <a href="#">Click here to send again</a>
      </p>
    </div> */}
    {/* <div className="logo-button-container">
    <img src={logo} alt="Logo" />
  </div> */}
</div>
</div>
  );
};

export default ThankYouPage;
