import React from 'react';
import logo from '../logo.png'

function Header() {
  return (
      <div className="logo-button-container">
        <img src={logo} alt="Logo" />
      </div>
  );
}

export default Header;
