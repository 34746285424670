// PieChartComponent.js
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const PieChartComponent = ({ data, width, height, outerRadius, innerRadius, customTooltip }) => {
  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={outerRadius}
        innerRadius={innerRadius} // This creates the doughnut effect
        labelLine={false}
        label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
      >
        <Cell key="cell-1" fill="#0070c0" />
        <Cell key="cell-2" fill="#c00000" />
      </Pie>
      <Tooltip content={customTooltip} />
      <Legend layout="horizontal" verticalAlign="bottom" align="center" />
    </PieChart>
  );
};

export default PieChartComponent;

// import React from 'react';
// import { PieChart, Pie, Cell, Tooltip } from 'recharts';

// const CustomTooltip = ({ active, payload, totalWeight }) => {
//   if (active && payload && payload.length) {
//     // Get both FIVE STARS and OPEX data values from payload
//     const fiveStarsData = payload.find((p) => p.name === "FIVE STARS");
//     const opexData = payload.find((p) => p.name === "OPEX");

//     // Ensure values exist, default to 0 if not
//     const fiveStarsValue = fiveStarsData ? fiveStarsData.value : 0;
//     const opexValue = opexData ? opexData.value : 0;

//     // Calculate total value
//     const totalValue = fiveStarsValue + opexValue;

//     // Get the current hovered slice's value and name
//     const { name, value } = payload[0];

//     // Calculate the percentage for the hovered slice
//     const percentage =
//       totalValue > 0 ? ((value / totalWeight) * 100).toFixed(2) : 0;

//     return (
//       <div className="custom-tooltip">
//         <p style={{ color: name === "FIVE STARS" ? "#0088FE" : "#FF0000" }}>
//           {`${name}: ${percentage}%`}
//         </p>
//       </div>
//     );
//   }
//   return null;
// };

// const PieChartComponent = ({ data, width, height, outerRadius, innerRadius }) => {
//   const totalWeight = data.reduce((acc, curr) => acc + curr.value, 0);

//   const COLORS = ["#0088FE", "#FF0000"];

//   return (
//     <PieChart width={width} height={height}>
//       <Pie
//         data={data}
//         dataKey="value"
//         nameKey="name"
//         cx="50%"
//         cy="50%"
//         outerRadius={outerRadius}
//         innerRadius={innerRadius}
//         fill="#8884d8"
//       >
//         {data.map((entry, index) => (
//           <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//         ))}
//       </Pie>
//       {/* Use CustomTooltip */}
//       <Tooltip content={<CustomTooltip totalWeight={totalWeight} />} />
//     </PieChart>
//   );
// };

// export default PieChartComponent;
